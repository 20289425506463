import { DomainConfigType } from 'utils/Domain/Domain';

type Locale = 'cs' | 'sk' | 'pl' | 'de' | 'en' | 'it' | 'de-AT' | 'es' | 'pt' | 'hu';

export const B2C_CZ_DOMAIN_ID = 1;
export const B2B_CZ_DOMAIN_ID = 2;
export const B2C_SK_DOMAIN_ID = 3;
export const B2B_SK_DOMAIN_ID = 4;
export const B2C_PL_DOMAIN_ID = 5;
export const B2B_PL_DOMAIN_ID = 6;
export const B2C_HU_DOMAIN_ID = 7;
export const B2B_HU_DOMAIN_ID = 8;
export const B2C_DE_DOMAIN_ID = 9;
export const B2B_DE_DOMAIN_ID = 10;
export const B2C_ES_DOMAIN_ID = 11;
export const B2C_PT_DOMAIN_ID = 12;
export const WEB_EN_DOMAIN_ID = 13;
export const WEB_IT_DOMAIN_ID = 14;
export const B2C_AT_DOMAIN_ID = 15;

export const isDomainLocale = (currentLocale: string, locale: Locale): boolean => currentLocale === locale;
export const isOneOfDomainLocales = (currentLocale: string, locales: Locale[]): boolean => {
    return locales.some((locale) => isDomainLocale(currentLocale, locale));
};

export const isDomain = (currentDomainId: number, expectedDomainId: number): boolean =>
    expectedDomainId === currentDomainId;
export const isNotDomain = (currentDomainId: number, notExpectedDomainId: number): boolean =>
    notExpectedDomainId !== currentDomainId;
export const isOneOfDomains = (currentDomainId: number, expectedDomainIds: number[]): boolean => {
    return expectedDomainIds.some((expectedDomainId) => isDomain(currentDomainId, expectedDomainId));
};
export const isNotOneOfDomains = (currentDomainId: number, notExpectedDomainIds: number[]): boolean => {
    return !notExpectedDomainIds.some((expectedDomainId) => isDomain(currentDomainId, expectedDomainId));
};

export const isB2bDomain = (domain: DomainConfigType): boolean => {
    return domain.type === 'b2b';
};

export const isWebDomain = (domain: DomainConfigType): boolean => {
    return domain.type === 'web';
};

export const isB2cDomain = (domain: DomainConfigType): boolean => {
    return domain.type === 'b2c';
};
