import type { UserConsentInPopupContainerProps } from './UserConsentInPopupContainer';
import { UserConsentSimplified } from './UserConsentSimplified/UserConsentSimplified';
import { useUserConsent } from './useUserConsent';
import { getUserConsentCookie } from 'helpers/cookies/getUserConsentCookie';
import { getUrlWithoutGetParameters } from 'helpers/parsing/urlParsing';
import { useIsMounted } from 'hooks/ui/useIsMounted';
import { useDomainConfig } from 'hooks/useDomainConfig';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

const UserConsentInPopupContainer = dynamic<UserConsentInPopupContainerProps>(
    () =>
        import('components/Blocks/UserConsent/UserConsentInPopupContainer').then(
            (component) => component.UserConsentInPopupContainer,
        ),
    {
        ssr: false,
    },
);

const UserConsentInPopupWrapper: FC<UserConsentInPopupContainerProps> = (props) => {
    return <UserConsentInPopupContainer {...props} />;
};

export const UserConsentContainer: FC = () => {
    const [shouldShowPopup, setShouldShowPopup] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const router = useRouter();
    const windowLocation = getUrlWithoutGetParameters(router.asPath);

    const { url } = useDomainConfig();
    const [consentUpdatePageUrl] = getInternationalizedStaticUrls(['/cookie-consent'], url);

    const onSaveCallback = useCallback(() => {
        if (getUserConsentCookie() !== null) {
            setIsVisible(false);
        }
    }, []);

    const {
        cookiePolicyUrl,
        formProviderMethods,
        onAcceptAllCallback,
        onRejectAllCallback,
        onSaveCookieChoicesCallback,
    } = useUserConsent(onSaveCallback);

    useEffect(() => {
        const setVisibility = () => {
            if (!!cookiePolicyUrl && !!consentUpdatePageUrl) {
                setIsVisible(![consentUpdatePageUrl, cookiePolicyUrl].includes(windowLocation));
            }
        };
        const setVisibilityTimeoutId = setTimeout(setVisibility, 1000);

        return () => {
            clearTimeout(setVisibilityTimeoutId);
        };
    }, [cookiePolicyUrl, consentUpdatePageUrl, windowLocation]);

    const isMounted = useIsMounted();

    if (!isVisible) {
        return null;
    }

    if (isMounted && shouldShowPopup) {
        return (
            <UserConsentInPopupWrapper
                cookiePolicyUrl={cookiePolicyUrl}
                formProviderMethods={formProviderMethods}
                onAcceptAllCallback={onAcceptAllCallback}
                onRejectAllCallback={onRejectAllCallback}
                onSaveCookieChoicesCallback={onSaveCookieChoicesCallback}
            />
        );
    }

    return (
        <UserConsentSimplified
            cookiePolicyUrl={cookiePolicyUrl}
            onAcceptAllCallback={onAcceptAllCallback}
            onRejectAllCallback={onRejectAllCallback}
            onShowUserConsentPopup={() => setShouldShowPopup(true)}
        />
    );
};
