import { PaczkomatExtendedPoint } from './types';
import { deleteCookie, getCookies, hasCookie, setCookie } from 'cookies-next';
import { PickupPointType } from 'types/pickupPoint';

const PACZKOMAT_COOKIE_NAME = 'paczkomatPickupPoint';

export function mapPaczkomatExtendedPoint(point: PaczkomatExtendedPoint): PickupPointType {
    return {
        identifier: point.name.toString(),
        name: point.name,
        email: null,
        telephone: point.phone_number ?? null,
        description: point.payment_point_descr ?? '',
        openingHours: null,
        street: mapStreetWithBuildingNumber(point),
        postcode: point.address_details.post_code ?? '',
        city: point.address_details.city,
        country: { code: 'PL', name: 'Polska' },
        disabled: false,
        reservationDisabled: false,
        locationLatitude: null,
        locationLongitude: null,
    };
}

const mapStreetWithBuildingNumber = (point: PaczkomatExtendedPoint): string => {
    let street = point.address_details.street;

    if (point.address_details.building_number !== null && point.address_details.building_number !== undefined) {
        street += ` ${point.address_details.building_number}`;
    }

    return street;
};

export const getPaczkomatCookie = (): PickupPointType | null => {
    const cookies = getCookies();

    if (hasCookie(PACZKOMAT_COOKIE_NAME)) {
        return cookies.paczkomatPickupPoint ? JSON.parse(decodeURIComponent(cookies.paczkomatPickupPoint)) : null;
    }

    return null;
};

export const setPaczkomatCookie = (mappedPaczkomatPoint: PickupPointType): void => {
    setCookie(PACZKOMAT_COOKIE_NAME, JSON.stringify(mappedPaczkomatPoint), {
        path: '/',
        maxAge: 60 * 60 * 24 * 30,
    });
};

export const removePaczkomatCookie = (): void => {
    deleteCookie(PACZKOMAT_COOKIE_NAME, {
        path: '/',
    });
};
