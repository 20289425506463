import { deleteCookie, getCookies, hasCookie, setCookie } from 'cookies-next';
import { PostOfficesBySearchApi, PostOfficesMarkersBySearchApi } from 'graphql/generated';
import { CountryType } from 'types/country';
import { MapMarkerType } from 'types/map';
import { PickupPointType } from 'types/pickupPoint';
import { SearchedStoresType } from 'types/store';

export const mapPostOfficeToStore = (
    postOffice: NonNullable<PostOfficesBySearchApi['postOfficesBySearch']>[number],
): SearchedStoresType => {
    return {
        availabilities: null,
        availabilityInformation: null,
        isAvailableForProducts: null,
        isAvailableForProductsReservation: null,
        store: {
            locationLatitude: postOffice.locationLatitude,
            locationLongitude: postOffice.locationLongitude,
            city: postOffice.city,
            country: getCountryByCountryCode(postOffice.countryCode || ''),
            email: null,
            isPickupPoint: true,
            coordinateDistance: null,
            name: postOffice.name,
            postcode: postOffice.postcode,
            slug: null,
            street: postOffice.address,
            telephone: null,
            uuid: postOffice.identifier,
            openingHours: postOffice.openingHours,
        },
    };
};

export const mapPostOfficesToMapMarker = (
    postOffices: PostOfficesMarkersBySearchApi['postOfficesBySearch'] | undefined,
): MapMarkerType[] => {
    if (!postOffices) {
        return [];
    }

    return postOffices.map((postOffice) => {
        return {
            postOffice: true,
            isPickupPoint: true,
            lat: parseFloat(postOffice.locationLatitude ?? ''),
            lng: parseFloat(postOffice.locationLongitude ?? ''),
            identifier: postOffice.identifier,
        };
    });
};

const getCountryByCountryCode = (countryCode: string): CountryType => {
    if (countryCode === 'CZ') {
        return {
            name: 'Česká republika',
            code: 'CZ',
        };
    }

    return {
        name: countryCode,
        code: countryCode,
    };
};

export const getPostOfficeCzCookie = (): PickupPointType | null => {
    const cookies = getCookies();
    if (hasCookie('postOffice')) {
        return cookies.postOffice ? JSON.parse(decodeURIComponent(cookies.postOffice)) : null;
    }

    return null;
};

const ONE_MONTH = 60 * 60 * 24 * 30;

export const setPostOfficeCzCookie = (mappedPostOfficePoint: PickupPointType): void => {
    setCookie('postOffice', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeCzCookie = (): void => {
    deleteCookie('postOffice', {
        path: '/',
    });
};

export const getPostOfficeHuCookie = (): PickupPointType | null => {
    const cookies = getCookies();
    if (hasCookie('postOfficeHu')) {
        return cookies.postOfficeHu ? JSON.parse(decodeURIComponent(cookies.postOfficeHu)) : null;
    }

    return null;
};

export const setPostOfficeHuCookie = (mappedPostOfficePoint: PickupPointType): void => {
    setCookie('postOfficeHu', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeHuCookie = (): void => {
    deleteCookie('postOfficeHu', {
        path: '/',
    });
};

export const getPostOfficeSkCookie = (): PickupPointType | null => {
    const cookies = getCookies();
    if (hasCookie('postOfficeSk')) {
        return cookies.postOfficeSk ? JSON.parse(decodeURIComponent(cookies.postOfficeSk)) : null;
    }

    return null;
};

export const setPostOfficeSkCookie = (mappedPostOfficePoint: PickupPointType): void => {
    setCookie('postOfficeSk', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeSkCookie = (): void => {
    deleteCookie('postOfficeSk', {
        path: '/',
    });
};

export const getPostOfficePbhCookie = (): PickupPointType | null => {
    const cookies = getCookies();
    if (hasCookie('postOfficePbh')) {
        return cookies.postOfficePbh ? JSON.parse(decodeURIComponent(cookies.postOfficePbh)) : null;
    }

    return null;
};

export const setPostOfficePbhCookie = (mappedPostOfficePoint: PickupPointType): void => {
    setCookie('postOfficePbh', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficePbhCookie = (): void => {
    deleteCookie('postOfficePbh', {
        path: '/',
    });
};
