import Button from 'components/Forms/Button/Button';
import { mapAddToCartPopupData } from 'connectors/cart/Cart';
import { useAddToCart } from 'hooks/cart/useAddToCart';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { createPersooEventForAddToCart } from 'modules/persoo/persooEvents';
import { AddToCartBasketSmallIcon, SpinnerIcon } from 'public/svg/IconsSvg';
import { usePersistStore } from 'store/usePersistStore';
import { GtmListNameType } from 'types/gtm';

type AddToCartProps = {
    productUuid: string;
    gtmListName: GtmListNameType;
    listIndex: number;
    isBookingOnly: boolean;
};

const AddToCart: FC<AddToCartProps> = ({ productUuid, gtmListName, listIndex, isBookingOnly }) => {
    const testIdentifier = 'blocks-product-addtocart';
    const t = useTypedTranslationFunction();
    const [changeCartItemQuantity, fetching] = useAddToCart(gtmListName);
    const { currencyCode } = useDomainConfig();
    const { updateUserState } = usePersistStore((s) => s);

    const onAddToCartHandler = async () => {
        const addToCartResult = await changeCartItemQuantity(productUuid, listIndex, 1, gtmListName);
        const mappedAddToCartResult = mapAddToCartPopupData(addToCartResult, currencyCode);

        if (mappedAddToCartResult) {
            createPersooEventForAddToCart(mappedAddToCartResult.catalogNumber);
            updateUserState({
                addToCartPopupData: {
                    gtmListName,
                    listIndex,
                    product: mappedAddToCartResult,
                },
            });
        }
    };

    return (
        <>
            <Button
                type="button"
                size="smallNarrow"
                name="add-to-cart"
                onClick={onAddToCartHandler}
                data-testid={testIdentifier}
            >
                {isBookingOnly ? (
                    t('Book')
                ) : (
                    <>
                        {fetching ? <SpinnerIcon /> : <AddToCartBasketSmallIcon className="h-4 w-4" />}
                        {t('Add to cart')}
                    </>
                )}
            </Button>
        </>
    );
};

export default AddToCart;
