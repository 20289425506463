import Button from 'components/Forms/Button/Button';
import { showInfoMessage } from 'components/Helpers/Toasts';
import { getNewProductForDrawer } from 'helpers/configurator/getNewProductForDrawer';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { usePersistStore } from 'store/usePersistStore';
import { ExtendedConfiguratorProductApi } from 'types/configurator';

type AddToConfiguratorProps = {
    product: ExtendedConfiguratorProductApi;
};

export const AddToConfigurator: FC<AddToConfiguratorProps> = ({ product }) => {
    const { t } = useTranslation();
    const { updateConfiguratorState, productsInDrawer, drawerSizes } = usePersistStore();

    const addProductToConfigurator = useCallback(
        (product: ExtendedConfiguratorProductApi) => {
            const newProductForDrawer = getNewProductForDrawer(productsInDrawer, drawerSizes, {
                id: Math.random() + product.uuid,
                height: product.flexiHeight,
                width: product.flexiWidth,
                imageHeight: product.flexiHeight,
                imageWidth: product.flexiWidth,
                image: product.flexiSpaceImage[0],
                alignment: 'vertical',
                name: product.fullName,
                uuid: product.uuid,
                catalogNumber: product.catalogNumber,
                slug: product.slug,
            });

            if (newProductForDrawer) {
                updateConfiguratorState({
                    productsInDrawer: [...productsInDrawer, newProductForDrawer],
                });
            } else {
                showInfoMessage(t('There is not enough space in the drawer for adding this component.'));
            }
        },
        [drawerSizes, productsInDrawer, updateConfiguratorState, t],
    );

    return (
        <Button size="smallNarrow" name="add-to-drawer" onClick={() => addProductToConfigurator(product)}>
            {t('Add to configurator')}
        </Button>
    );
};
