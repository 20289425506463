import Cart from './Cart/Cart';
import DesktopMenu from './DesktopMenu/DesktopMenu';
import HamburgerButton from './HamburgerButton/HamburgerButton';
import { MenuIconic } from './MenuIconic/MenuIconic';
import { MobileMenuProps } from './MobileMenu/MobileMenu';
import Search from './Search/Search';
import Overlay from 'components/Basic/Overlay/Overlay';
import Portal from 'components/Basic/Portal/Portal';
import Contact from 'components/Layout/Header/Contact/Contact';
import { isWebDomain } from 'helpers/isDomainLocale';
import useTimeout from 'hooks/helpers/UseTimeout';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { useMountAnimation } from 'hooks/ui/useMountAnimation';
import { useDomainConfig } from 'hooks/useDomainConfig';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

const MENU_HOVER_DELAY = 300;

const MobileMenu = dynamic<MobileMenuProps>(
    import('./MobileMenu/MobileMenu').then((component) => component.MobileMenu),
);

const MOBILE_MENU_ANIMATION = 150;

export const HeaderBottom: FC = () => {
    const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
    const [isMobileMenuOpen, isMobileMenuInStableAnimationState, setIsMobileMenuOpen] = useMountAnimation(
        MOBILE_MENU_ANIMATION,
        false,
    );
    const [isDesktopMenuButtonHovered, setIsDesktopMenuButtonHovered] = useState(false);
    const [isDesktopMenuHovered, setIsDesktopMenuHovered] = useState(false);
    const { events } = useRouter();
    const isDesktop = useMediaMin('vl');
    const domainConfig = useDomainConfig();

    // prevent the menu from being accidentally opened and closed
    useTimeout(
        () => setIsDesktopMenuOpen(true),
        !isDesktopMenuOpen && (isDesktopMenuButtonHovered || isDesktopMenuHovered) ? MENU_HOVER_DELAY : null,
    );

    useTimeout(
        () => setIsDesktopMenuOpen(false),
        isDesktopMenuOpen && !isDesktopMenuButtonHovered && !isDesktopMenuHovered ? MENU_HOVER_DELAY : null,
    );

    useEffectOnce(() => {
        const closeMenuOnRouteComplete = () => {
            setIsMobileMenuOpen(false);
            setIsDesktopMenuOpen(false);
            setIsDesktopMenuButtonHovered(false);
            setIsDesktopMenuHovered(false);
        };
        events.on('routeChangeComplete', closeMenuOnRouteComplete);

        return () => {
            events.off('routeChangeComplete', closeMenuOnRouteComplete);
        };
    });

    return (
        <>
            <div className="flex w-[135px] flex-1 justify-end bg-primary vl:hidden">
                <MenuIconic />
                {!isWebDomain(domainConfig) && <Cart isDesktop={false} />}
            </div>
            <div className="flex items-center justify-start vl:h-[80px] vl:gap-x-4 notVl:bg-primary">
                <HamburgerButton
                    isMenuOpened={isDesktopMenuOpen}
                    showCloseIcon={isDesktop === false && isMobileMenuOpen}
                    onMouseEnter={isDesktop ? () => setIsDesktopMenuButtonHovered(true) : undefined}
                    onMouseLeave={isDesktop ? () => setIsDesktopMenuButtonHovered(false) : undefined}
                    onClickCallback={(event) => {
                        if (isDesktop) {
                            event.preventDefault();
                            setIsDesktopMenuOpen((prev) => prev);
                        } else {
                            setIsMobileMenuOpen(!isMobileMenuOpen);
                        }
                    }}
                />
                <Contact desktopOnly />
            </div>
            {isMobileMenuOpen && (
                <MobileMenu
                    shouldStartAnimation={isMobileMenuInStableAnimationState}
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
            )}

            <div
                className={twJoin(
                    'flex items-center vl:h-[80px] vl:flex-1 vl:gap-x-4 vl:px-5 xl:pr-[10%] notVl:w-full',
                    isMobileMenuOpen && 'pointer-events-none opacity-30',
                )}
            >
                <Search />
            </div>
            <div
                className={twJoin(
                    'flex items-center justify-end gap-x-0 notVl:hidden',
                    isWebDomain(domainConfig) ? 'min-w-[150px]' : 'min-w-[280px]',
                )}
            >
                <MenuIconic />
                {!isWebDomain(domainConfig) && <Cart isDesktop />}
            </div>
            {isDesktop && (
                <>
                    {isDesktopMenuOpen && (
                        <DesktopMenu
                            isOpen={isDesktopMenuOpen}
                            onMouseEnter={() => setIsDesktopMenuHovered(true)}
                            onMouseLeave={() => setIsDesktopMenuHovered(false)}
                        />
                    )}
                    <Overlay isActive={isDesktopMenuOpen} withWrapper={false} className="z-overlay" />
                </>
            )}
            {!isDesktop && isMobileMenuOpen && (
                <Portal>
                    <Overlay isActive className="z-overlay" onClick={() => setIsMobileMenuOpen(false)} />
                </Portal>
            )}
        </>
    );
};
