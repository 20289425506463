import UserMenuDesktop from './UserMenuDesktop';
import UserMenuMobile from './UserMenuMobile';
import Portal from 'components/Basic/Portal/Portal';
import { useMenuIconicContentOffset } from 'components/Layout/Header/HeaderBottom/MenuIconic/useMenuIconicContentOffset';
import { useCurrentCustomerData } from 'connectors/customer/CurrentCustomer';
import { useSettings } from 'connectors/Settings/Settings';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { RefObject, useMemo, useRef } from 'react';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

type Props = {
    isMobileView: boolean | undefined;
    userMenuLinkRef: RefObject<HTMLDivElement>;
    isActive: boolean;
};

export type MenuItemProps = {
    link: string | null;
    title: string;
    count: number | null;
    show: boolean;
};

const UserMenu: FC<Props> = ({ isMobileView, userMenuLinkRef, isActive }) => {
    const t = useTypedTranslationFunction();

    const customerUserData = useCurrentCustomerData();
    const settings = useSettings();
    const domainConfig = useDomainConfig();

    const userMenuPopupRef = useRef<HTMLDivElement>(null);
    useMenuIconicContentOffset(userMenuLinkRef, userMenuPopupRef);

    const [
        customerProfileUrl,
        customerEditProfileUrl,
        customerOrdersUrl,
        customerMyPointsUrl,
        customerWatchedItemsUrl,
    ] = getInternationalizedStaticUrls(
        ['/customer', '/customer/edit-profile', '/customer/orders', '/customer/my-points', '/customer/watched-items'],
        domainConfig.url,
    );

    const menuItems: MenuItemProps[] = useMemo(() => {
        return [
            {
                link: customerProfileUrl,
                title: t('My information'),
                count: null,
                show: true,
            },
            {
                link: customerEditProfileUrl,
                title: t('My profile'),
                count: null,
                show: true,
            },
            {
                link: customerOrdersUrl,
                title: t('My purchases'),
                count: null,
                show: true,
            },
            {
                link: customerMyPointsUrl,
                title: t('My points'),
                count: null,
                show: settings?.loyaltyClub.enabled === true,
            },
            {
                link: customerWatchedItemsUrl,
                title: t('Watched items'),
                count: customerUserData?.pillCounts.watchDogItems ?? null,
                show: true,
            },
        ];
    }, [
        customerEditProfileUrl,
        customerMyPointsUrl,
        customerOrdersUrl,
        customerProfileUrl,
        customerUserData?.pillCounts.watchDogItems,
        customerWatchedItemsUrl,
        settings,
        t,
    ]);

    return isMobileView ? (
        <Portal>
            <UserMenuMobile menuItems={menuItems} ref={userMenuPopupRef} />
        </Portal>
    ) : (
        <UserMenuDesktop isActive={isActive} menuItems={menuItems} />
    );
};

export default UserMenu;
