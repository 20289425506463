import { ApplicationErrors, ApplicationErrorsType } from './applicationErrors';
import { Translate } from 'next-translate';

const getErrorMessageTranslation = (key: ApplicationErrorsType, t: Translate): string | undefined =>
    ({
        [ApplicationErrors.default]: t('Unknown error.'),
        [ApplicationErrors['cart-not-found']]: t('Cart not found.'),
        [ApplicationErrors['cart-unavailable']]: t('Your login has expired.'),
        [ApplicationErrors['max-allowed-limit']]: t('Max allowed limit reached.'),
        [ApplicationErrors['packetery-address-id-invalid']]: t('Invalid Packetery address id.'),
        [ApplicationErrors['invalid-credentials']]: t('Invalid credentials.'),
        [ApplicationErrors['invalid-refresh-token']]: t('Invalid refresh token.'),
        [ApplicationErrors['loyalty-club-points-overdraft']]: t('Loyalty club point overdraft.'),
        [ApplicationErrors['order-emails-not-sent']]: t('Automatic order emails was not sent.'),
        [ApplicationErrors['order-empty-cart']]: t('Cart is empty.'),
        [ApplicationErrors['personal-data-request-type-invalid']]: t('Invalid request type.'),
        [ApplicationErrors['blog-category-not-found']]: t('Category not found.'),
        [ApplicationErrors['image-type-invalid']]: t('Invalid image type.'),
        [ApplicationErrors['order-not-found']]: t('Order not found.'),
        [ApplicationErrors['personal-data-hash-invalid']]: t('Invalid hash.'),
        [ApplicationErrors['product-price-missing']]: t('Product price is missing.'),
        [ApplicationErrors['promo-code-already-applied']]: t('PROMO_CODE_ALREADY_APPLIED'),
        [ApplicationErrors['promo-code-at-most-one-percentage']]: t('PROMO_CODE_AT_MOST_ONE_PERCENTAGE'),
        [ApplicationErrors['promo-code-discount-coupon-already-applied']]: t(
            'PROMO_CODE_ALREADY_APPLIED_DISCOUNT_COUPON',
        ),
        [ApplicationErrors['reservations-disabled-at-place']]: t('Reservations are disabled at this place.'),
        [ApplicationErrors['no-result-found-for-slug']]: t('No result found for slug.'),
        [ApplicationErrors['store-not-found']]: t('Store not found.'),
        [ApplicationErrors['loyalty-club-points-unverified']]: t('Loyalty points in this order could not be redeemed.'),
        [ApplicationErrors['loyalty-club-points-changed']]: t(
            'We have updated your points status, please complete the order again.',
        ),
        [ApplicationErrors['gift-voucher-not-applicable-for-personal-pickup']]: t(
            'GIFT_CODE_CANNOT_BE_APPLIED_WITH_RESERVATION',
        ),
        [ApplicationErrors['promo-code-does-not-meet-minimum-order-price']]: t(
            'PROMO_CODE_DOES_NOT_MEET_MINIMUM_ORDER_PRICE',
        ),
        [ApplicationErrors.GIFT_CODE_CANNOT_BE_APPLIED_ON_CART_ITEMS]: t('GIFT_CODE_CANNOT_BE_APPLIED_ON_CART_ITEMS'),
        [ApplicationErrors['order-stock-availability-error']]: t(
            'Some of the products are not in sufficient quantity in the given warehouse.',
        ),
        [ApplicationErrors['loyalty-club-points-used-for-voucher']]: t(
            'Loyalty points are not allowed to be used to purchase vouchers.',
        ),
        [ApplicationErrors['promo-code-available-for-registered-customer-user-only']]: t(
            'Promo code is available for registered customers only.',
        ),
        [ApplicationErrors['promo-code-not-usable-on-vouchers']]: t('Promo code cannot be used on vouchers.'),
        [ApplicationErrors['wishlist-not-found']]: t('Wishlist not found.'),
        [ApplicationErrors['wishlist-item-already-exists']]: t('Product in wishlist already exists.'),
        [ApplicationErrors['wishlist-item-not-found']]: t('Product in wishlist not found.'),
    }[key]);

export const hasErrorMessage = (errorCode: string, t: Translate): boolean => {
    return getErrorMessageTranslation(errorCode as ApplicationErrorsType, t) !== undefined;
};

export const getErrorMessage = (errorCode: string, t: Translate): string => {
    const translationString = getErrorMessageTranslation(errorCode as ApplicationErrorsType, t);

    return translationString !== undefined ? translationString : t('Unknown error.');
};
