import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Overlay from 'components/Basic/Overlay/Overlay';
import { LoginUnderMenuWrapper } from 'components/Blocks/Popup/Login/LoginUnderMenuWrapper';
import UserMenu from 'components/Layout/Header/HeaderBottom/UserMenu/UserMenu';
import { mobileFirstSizes } from 'components/Theme/mediaQueries';
import { isWebDomain } from 'helpers/isDomainLocale';
import useTimeout from 'hooks/helpers/UseTimeout';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { useWishlist } from 'hooks/wishlist/useWishlist';
import { useRouter } from 'next/router';
import { MenuHeartIcon, MenuUserIcon } from 'public/svg/IconsSvg';
import { memo, MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

const TEST_IDENTIFIER = 'layout-header-menuiconic';

const MENU_HOVER_DELAY = 300;

const isBrowserPasswordManagerHovered = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    return e.relatedTarget === window;
};

export const MenuIconic = memo(() => {
    const t = useTypedTranslationFunction();
    const router = useRouter();
    const { isUserLoggedIn } = useCurrentUserData();

    const [isUserPopupOpened, setIsUserPopupOpened] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [isUserMenuHovered, setIsUserMenuHovered] = useState(false);
    const userMenuLinkRef = useRef<HTMLDivElement>(null);
    const currentDomainConfig = useDomainConfig();
    const { wishlist } = useWishlist();
    const [wishlistUrl] = getInternationalizedStaticUrls(['/wishlist'], currentDomainConfig.url);

    useEffect(() => {
        if (isUserLoggedIn) {
            setIsUserPopupOpened(false);
        }
    }, [isUserLoggedIn]);

    useTimeout(
        () => setIsUserPopupOpened(true),
        !isUserPopupOpened && isUserMenuHovered && !isMobileView ? MENU_HOVER_DELAY : null,
    );

    useTimeout(
        () => setIsUserPopupOpened(false),
        isUserPopupOpened && !isUserMenuHovered && !isMobileView ? MENU_HOVER_DELAY : null,
    );

    useEffect(() => {
        const closeLoginPopup = () => {
            setIsUserMenuHovered(false);
            setIsUserPopupOpened(false);
        };

        router.events.on('routeChangeStart', closeLoginPopup);

        const handler = () => setIsMobileView(window.innerWidth < mobileFirstSizes.vl);
        handler();
        window.addEventListener('resize', handler, { passive: true });

        return () => {
            window.removeEventListener('resize', handler);
            router.events.off('routeChangeStart', closeLoginPopup);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ul className="flex h-full vl:not-last:mr-2.5" data-testid={TEST_IDENTIFIER}>
                <li className="relative space-x-1 self-center notVl:h-[45px] notVl:w-[45px] notVl:items-center notVl:justify-center notVl:space-x-0 notVl:bg-primary notVl:text-white">
                    <ExtendedNextLink
                        href={wishlistUrl}
                        type="static"
                        className="flex h-full w-full cursor-pointer items-center justify-center font-bold text-currentColor no-underline transition-all vl:h-[48px] vl:rounded-md vl:px-3 vl:hover:bg-white vl:hover:no-underline notVl:hover:text-white"
                    >
                        <>
                            <div className="relative">
                                <MenuHeartIcon className="mr-2.5 h-6 w-6 stroke-white text-currentColor vl:stroke-black notVl:mr-0" />
                                {wishlist?.products.length && (
                                    <span
                                        className="absolute -top-2 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-red text-[10px] font-bold text-white notVl:-right-2"
                                        data-testid={TEST_IDENTIFIER + '-wishlist-itemcount'}
                                    >
                                        {wishlist.products.length}
                                    </span>
                                )}
                            </div>
                            <span className="notVl:hidden">{t('Favorite')}</span>
                        </>
                    </ExtendedNextLink>
                </li>

                {!isWebDomain(currentDomainConfig) && (
                    <li
                        className={twJoin(
                            'relative space-x-1 self-center notVl:h-[45px] notVl:w-[45px] notVl:items-center notVl:justify-center notVl:space-x-0',
                            isUserPopupOpened && 'z-menu notVl:bg-white notVl:text-primary',
                            !isUserPopupOpened && 'notVl:bg-primary notVl:text-white',
                        )}
                        data-testid={TEST_IDENTIFIER + '-2'}
                    >
                        <div
                            className={twJoin(
                                'flex h-full w-full cursor-pointer items-center justify-center font-bold no-underline transition-all vl:h-[48px] vl:rounded-md vl:px-3 vl:hover:bg-white vl:hover:no-underline',
                                isUserPopupOpened && 'bg-white text-primary',
                            )}
                            onMouseEnter={!isMobileView ? () => setIsUserMenuHovered(true) : undefined}
                            onMouseLeave={
                                !isMobileView
                                    ? (e) => !isBrowserPasswordManagerHovered(e) && setIsUserMenuHovered(false)
                                    : undefined
                            }
                            ref={userMenuLinkRef}
                        >
                            <button
                                className="text-currentColor"
                                onClick={isMobileView ? () => setIsUserPopupOpened((prev) => !prev) : undefined}
                            >
                                <MenuUserIcon className="mr-2.5 h-6 w-6 stroke-white text-currentColor vl:stroke-black notVl:mr-0" />
                            </button>
                            <span className="notVl:hidden">{isUserLoggedIn ? t('My account') : t('Log in')}</span>
                            {isUserPopupOpened && isUserLoggedIn && (
                                <UserMenu
                                    isActive={isUserPopupOpened}
                                    isMobileView={isMobileView}
                                    userMenuLinkRef={userMenuLinkRef}
                                />
                            )}
                            {isUserPopupOpened && !isUserLoggedIn && (
                                <LoginUnderMenuWrapper isMobileView={isMobileView} userMenuLinkRef={userMenuLinkRef} />
                            )}
                        </div>
                    </li>
                )}
            </ul>
            {!isWebDomain(currentDomainConfig) && (
                <Overlay
                    onMouseEnter={() => {
                        setIsUserMenuHovered(false);
                    }}
                    onClick={() => {
                        setIsUserPopupOpened(false);
                        setIsUserMenuHovered(false);
                    }}
                    withWrapper={false}
                    isActive={isUserPopupOpened}
                    className="z-overlay"
                />
            )}
        </>
    );
});

MenuIconic.displayName = 'MenuIconic';
