import { ContactInformationFormType } from 'types/form';
import { SelectOptionType } from 'types/selectOptions';
import { StateCreator } from 'zustand';

const initialState: ContactInformationFormType = {
    email: '',
    register: false,
    passwordFirst: '',
    passwordSecond: '',
    companyCustomer: undefined,
    telephone: '',
    birthDay: null,
    firstName: '',
    lastName: '',
    street: '',
    city: '',
    postcode: '',
    country: { value: '', label: '' },
    companyName: '',
    companyName2: '',
    companyEmail: '',
    companyTelephone: '',
    companyNumber: '',
    companyTaxNumber: '',
    companyTaxIdNumber: '',
    differentDeliveryAddress: false,
    deliveryFirstName: '',
    deliveryLastName: '',
    deliveryCompanyName: '',
    deliveryCompanyName2: '',
    deliveryTelephone: '',
    deliveryEmail: '',
    deliveryStreet: '',
    deliveryCity: '',
    deliveryPostcode: '',
    deliveryCountry: { value: '', label: '' },
    createInvoice: false,
    gdprConsents: [],
    note: '',
    isLoginPopupOpen: false,
    sendQuestionnaire: false,
};

export type ContactInformationSlice = ContactInformationFormType & {
    updateContactInformationState: (value: Partial<ContactInformationSlice>) => void;
    updateDeliveryAddressState: (
        value: Partial<{
            street: string;
            city: string;
            postcode: string;
            country: SelectOptionType;
            firstName?: string;
            lastName?: string;
        }>,
    ) => void;
    resetContactInformationState: () => void;
};

export const createContactInformationSlice: StateCreator<ContactInformationSlice> = (set) => ({
    ...initialState,

    updateContactInformationState: (value) => {
        set(value);
    },

    resetContactInformationState: () => {
        set(initialState);
    },

    updateDeliveryAddressState: (value) => {
        set({
            deliveryFirstName: value.firstName ?? '',
            deliveryLastName: value.lastName ?? '',
            ...value,
        });
    },
});
