import Heading from 'components/Basic/Heading/Heading';
import { ProductItemSkeleton } from 'components/Blocks/Product/List/ListedItem/ProductItemSkeleton';
import ProductsSlider from 'components/Blocks/Product/ProductsSlider';
import Webline from 'components/Layout/Webline/Webline';
import { useLastVisitedProducts } from 'connectors/products/Products';
import { createEmptyArray } from 'helpers/arrayUtils';
import { useDeepCompare } from 'hooks/helpers/useDeepCompare';
import { useIntersection } from 'hooks/ui/useIntersection';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import { useSessionStore } from 'store/useSessionStore';

const GTM_LIST_NAME = 'last visited products' as const;

const LastVisitedProducts: FC = () => {
    const { t } = useTranslation();
    const lastVisitedWrapperRef = useRef<HTMLDivElement>(null);
    const intersection = useIntersection(lastVisitedWrapperRef);
    const lastVisitedProductsCatNumbers = useSessionStore((s) => s.lastVisitedProductsCatNumbers);
    const [intersectedAtLeastOnce, setIntersectedAtLeastOnce] = useState(false);
    const { lastVisitedProducts: lastVisitedProductsApi, lastVisitedProductsFetching } = useLastVisitedProducts(
        intersectedAtLeastOnce ? lastVisitedProductsCatNumbers : [],
    );
    const lastVisitedProducts = useDeepCompare(lastVisitedProductsApi) || lastVisitedProductsApi;
    const displayLastVisitedBlock = lastVisitedProductsCatNumbers.length;

    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIntersectedAtLeastOnce(true);
        }
    }, [intersection?.isIntersecting]);

    return (
        <Webline className={displayLastVisitedBlock ? 'block' : 'hidden'}>
            <div ref={lastVisitedWrapperRef}>
                {displayLastVisitedBlock && (
                    <>
                        <Heading type="h2">{t('Last visited products')}</Heading>
                        {lastVisitedProducts?.length && !lastVisitedProductsFetching ? (
                            <ProductsSlider products={lastVisitedProducts} gtmListName={GTM_LIST_NAME} />
                        ) : (
                            <div className="flex">
                                {createEmptyArray(2).map((_, index) => (
                                    <ProductItemSkeleton key={index} className="w-1/2 border-0 lg:w-1/3 vl:w-1/5" />
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        </Webline>
    );
};

export default LastVisitedProducts;
