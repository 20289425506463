import { BalikovnaExtendedPoint } from './types';
import { deleteCookie, getCookies, hasCookie, setCookie } from 'cookies-next';
import { PickupPointType } from 'types/pickupPoint';

const BALIKOVNA_COOKIE_NAME = 'balikovnaPickupPoint';
const BALIKOVNA_MAX_AGE = 60 * 60 * 24 * 30;

export function mapBalikovnaExtendedPoint(point: BalikovnaExtendedPoint): PickupPointType {
    return {
        identifier: point.zip,
        name: point.name,
        email: null,
        telephone: null,
        description: '',
        openingHours: null,
        street: point.address ? mapAddressToStreet(point.address) : '',
        postcode: point.zip,
        city: point.municipality_name,
        country: { code: 'CZ', name: 'Česká republika' },
        disabled: false,
        reservationDisabled: false,
        locationLatitude: null,
        locationLongitude: null,
    };
}

const mapAddressToStreet = (address: string): string => {
    const parts = address.split(',');

    return parts[0];
};

export const getBalikovnaCookie = (): PickupPointType | null => {
    const cookies = getCookies();

    if (hasCookie(BALIKOVNA_COOKIE_NAME)) {
        return cookies[BALIKOVNA_COOKIE_NAME] ? JSON.parse(decodeURIComponent(cookies[BALIKOVNA_COOKIE_NAME])) : null;
    }

    return null;
};

export const setBalikovnaCookie = (mappedBalikovnaPoint: PickupPointType): void => {
    setCookie(BALIKOVNA_COOKIE_NAME, JSON.stringify(mappedBalikovnaPoint), {
        path: '/',
        maxAge: BALIKOVNA_MAX_AGE,
    });
};

export const removeBalikovnaCookie = (): void => {
    deleteCookie(BALIKOVNA_COOKIE_NAME, {
        path: '/',
    });
};
