import { deleteCookie, getCookies, hasCookie, setCookie } from 'cookies-next';
import { GetServerSidePropsContext, NextPageContext } from 'next';

const COOKIE_NAME = 'cartUuid';

export const setCartUuidToCookies = (storeUuid: string) => {
    setCookie(COOKIE_NAME, storeUuid, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30,
    });
};

export const getCartUuidCookie = (): string | null => {
    const cookies = getCookies();
    if (hasCookie(COOKIE_NAME)) {
        return cookies[COOKIE_NAME] ?? null;
    }

    return null;
};

export const destroyCartUuidCookie = () => {
    deleteCookie(COOKIE_NAME, {
        path: '/',
    });
};

export const getCartUuidCookieOnSsr = (context: GetServerSidePropsContext | NextPageContext): string | null => {
    if (!context.req) {
        return null;
    }

    const cartUuidCookie = 'cookies' in context.req ? context.req.cookies[COOKIE_NAME] : null;

    if (cartUuidCookie) {
        return cartUuidCookie;
    }

    return null;
};
