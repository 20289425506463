import { getIsProductHaveCollision, getIsProductHaveSpaceBeforeDrawerEnding } from './configuratorHelpers';
import { ConfiguratorDrawerSizesType, ConfiguratorProductType } from 'types/configurator';

export const getIsProductAbleToRotate = (
    productToCheck: ConfiguratorProductType,
    productsInDrawer: ConfiguratorProductType[],
    drawerSizes: ConfiguratorDrawerSizesType,
) => {
    const isProductHaveSpaceBeforeDrawerEnding = getIsProductHaveSpaceBeforeDrawerEnding(productToCheck, drawerSizes);

    return (
        !getIsProductHaveCollision(productToCheck, productsInDrawer) &&
        isProductHaveSpaceBeforeDrawerEnding.byLength &&
        isProductHaveSpaceBeforeDrawerEnding.byWidth
    );
};

export const rotateProduct = (productToRotate: ConfiguratorProductType): ConfiguratorProductType => {
    return {
        ...productToRotate,
        alignment: productToRotate.alignment === 'horizontal' ? 'vertical' : 'horizontal',
        height: productToRotate.width,
        width: productToRotate.height,
    };
};
