import { deleteCookie, getCookies, hasCookie, setCookie } from 'cookies-next';
import { GetServerSidePropsContext, NextPageContext } from 'next';

const COOKIE_NAME = 'defaultStoreUuid';

export const setDefaultStoreUuidToCookies = (storeUuid: string) => {
    setCookie(COOKIE_NAME, storeUuid, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30,
    });
};

export const getDefaultStoreUuidCookie = (): string | null => {
    const cookies = getCookies();
    if (hasCookie(COOKIE_NAME)) {
        return cookies[COOKIE_NAME] ?? null;
    }

    return null;
};

export const destroyDefaultStoreUuidCookie = () => {
    deleteCookie(COOKIE_NAME, {
        path: '/',
    });
};

export const getDefaultStoreUuidCookieOnSsr = (context: GetServerSidePropsContext | NextPageContext): string | null => {
    if (!context.req) {
        return null;
    }

    const defaultStoreUuidCookie = 'cookies' in context.req ? context.req.cookies[COOKIE_NAME] : null;

    if (defaultStoreUuidCookie) {
        return defaultStoreUuidCookie;
    }

    return null;
};
