import { getIsProductHaveCollision, getIsProductHaveSpaceBeforeDrawerEnding } from './configuratorHelpers';
import { rotateProduct } from './configuratorRotationHelper';
import { createEmptyArray } from 'helpers/arrayUtils';
import { ConfiguratorDrawerSizesType, ConfiguratorProductType } from 'types/configurator';

const getIsProductAvailableToAdd = (
    productsInDrawer: ConfiguratorProductType[],
    drawerSizes: ConfiguratorDrawerSizesType,
    potentialNewProduct: ConfiguratorProductType,
) => {
    const isProductHaveSpaceBeforeDrawerEnding = getIsProductHaveSpaceBeforeDrawerEnding(
        potentialNewProduct,
        drawerSizes,
    );

    return (
        !getIsProductHaveCollision(potentialNewProduct, productsInDrawer) &&
        isProductHaveSpaceBeforeDrawerEnding.byLength &&
        isProductHaveSpaceBeforeDrawerEnding.byWidth
    );
};

export const getNewProductForDrawer = (
    productsInDrawer: ConfiguratorProductType[],
    drawerSizes: ConfiguratorDrawerSizesType,
    productToAdd: Omit<ConfiguratorProductType, 'coordinates'>,
    shouldReturnOnlyRotatedProduct = false,
): ConfiguratorProductType | null => {
    let newProductForDrawer = null as ConfiguratorProductType | null;

    createEmptyArray(drawerSizes.width).forEach((_, potentialXCoordinate) => {
        createEmptyArray(drawerSizes.length).forEach((_, potentialYCoordinate) => {
            if (newProductForDrawer) {
                return;
            }

            const potentialNewProduct = {
                ...productToAdd,
                coordinates: { x: potentialXCoordinate, y: potentialYCoordinate },
            };

            if (
                getIsProductAvailableToAdd(productsInDrawer, drawerSizes, potentialNewProduct) &&
                !shouldReturnOnlyRotatedProduct
            ) {
                newProductForDrawer = potentialNewProduct;
            } else {
                const rotatedPotentialProduct = rotateProduct(potentialNewProduct);

                if (getIsProductAvailableToAdd(productsInDrawer, drawerSizes, rotatedPotentialProduct)) {
                    newProductForDrawer = rotatedPotentialProduct;
                }
            }
        });

        return null;
    });

    return newProductForDrawer;
};
