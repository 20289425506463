import { TransportsQueryApi } from 'graphql/generated';
import { PaymentType } from 'types/payment';
import { PickupPointType } from 'types/pickupPoint';
import { PriceType } from 'types/price';

export type MappedTransportType = {
    uuid: string;
    name: string;
    description: string;
    instruction: string;
    price: PriceType;
    image: string | null;
    payments: PaymentType[];
    daysUntilDelivery: number;
    deliveryDaysFromCentralStore: string | null;
    isPersonalPickup: boolean;
    stores: PickupPointType[];
    transportType: {
        code: string;
    };
    disabled: boolean;
    isDeliveryDateDisplayed: boolean;
    postcodeLimitationMode: string | null;
    postcodeLimitation: string | null;
};

export type TransportType = MappedTransportType | TransportsQueryApi['transports'][0];

export enum TransportTypeCodeEnum {
    COMMON = 'common',
    PACKETERY = 'packetery',
    PACZKOMAT = 'paczkomat',
    BALIKOVNA = 'balikovna',
    POST_OFFICE_PARCEL_DELIVERY_CZ = 'postOfficeParcelDelivery',
    POST_OFFICE_PARCEL_DELIVERY_HU = 'postOfficeParcelDeliveryHu',
    POST_OFFICE_PARCEL_DELIVERY_SK = 'postOfficeParcelDeliverySk',
    PBH = 'pbh',
}

export enum PostcodeLimitationMode {
    ALLOW = 'allow',
    DENY = 'deny',
}
