import { showErrorMessage, showSuccessMessage } from 'components/Helpers/Toasts';
import { mapListedProductType } from 'connectors/products/Products';
import {
    useAddProductToWishlistMutationApi,
    useCleanWishlistMutationApi,
    useRemoveProductFromWishlistMutationApi,
    useSharedWishlistQueryApi,
    useWishlistQueryApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { usePersistStore } from 'store/usePersistStore';
import { ListedProductType } from 'types/product';
import { CombinedError } from 'urql';

export const useWishlist = () => {
    const { t } = useTranslation();

    const { isUserLoggedIn } = useCurrentUserData();
    const [isFetchingPaused, setIsFetchingPaused] = useState(true);

    const updateWishlistUuid = usePersistStore((s) => s.updateWishlistUuid);
    const wishlistUuid = usePersistStore((s) => s.wishlistUuid);

    const [, addProductToWishlist] = useAddProductToWishlistMutationApi();
    const [, removeProductFromWishlist] = useRemoveProductFromWishlistMutationApi();
    const [, cleanWishlist] = useCleanWishlistMutationApi();
    const [{ data, fetching, error }] = useWishlistQueryApi({
        variables: { wishlistUuid },
        pause: isFetchingPaused,
    });
    useQueryError(error, error instanceof CombinedError);

    useEffect(() => {
        setIsFetchingPaused(!wishlistUuid && !isUserLoggedIn);
    }, [isUserLoggedIn, wishlistUuid]);

    useEffect(() => {
        if (data?.wishlist?.uuid) {
            updateWishlistUuid(data.wishlist.uuid);
        }
    }, [data?.wishlist?.uuid, updateWishlistUuid]);

    const handleCleanWishlist = async () => {
        const cleanWishlistResult = await cleanWishlist({ wishlistUuid });

        if (cleanWishlistResult.error) {
            showErrorMessage(t('Unable to clean wishlist.'));
        } else {
            showSuccessMessage(t('Wishlist was cleaned.'));
            updateWishlistUuid(null);
        }
    };

    const handleAddToWishlist = async (productUuid: string) => {
        const addProductToWishlistResult = await addProductToWishlist({
            productUuid,
            wishlistUuid: isUserLoggedIn ? null : wishlistUuid,
        });

        if (addProductToWishlistResult.error) {
            showErrorMessage(t('Unable to add product to wishlist.'));
        } else {
            showSuccessMessage(t('The item has been added to your wishlist.'));
            updateWishlistUuid(addProductToWishlistResult.data?.addProductToWishlist.uuid ?? null);
        }
    };

    const handleRemoveFromWishlist = async (productUuid: string) => {
        const removeProductFromWishlistResult = await removeProductFromWishlist({ productUuid, wishlistUuid });

        if (removeProductFromWishlistResult.error) {
            showErrorMessage(t('Unable to remove product from wishlist.'));
        } else {
            if (!removeProductFromWishlistResult.data?.removeProductFromWishlist) {
                updateWishlistUuid(null);
            }
            showSuccessMessage(t('The item has been removed from your wishlist.'));
        }
    };

    const isProductInWishlist = (productUuid: string) =>
        !!data?.wishlist?.products.find((product) => product.uuid === productUuid);

    const toggleProductInWishlist = (productUuid: string) => {
        if (isProductInWishlist(productUuid)) {
            handleRemoveFromWishlist(productUuid);
        } else {
            handleAddToWishlist(productUuid);
        }
    };

    useEffect(() => {
        if (!isUserLoggedIn && data?.wishlist && wishlistUuid !== data.wishlist.uuid) {
            updateWishlistUuid(data.wishlist.uuid);
        }
    }, [data?.wishlist, data?.wishlist?.uuid, isUserLoggedIn, updateWishlistUuid, wishlistUuid]);

    return {
        wishlist: data?.wishlist || undefined,
        fetching,
        isProductInWishlist,
        handleCleanWishlist,
        toggleProductInWishlist,
    };
};

export const useSharedWishlist = (catnums: string[]): { products: ListedProductType[]; fetching: boolean } => {
    const { currencyCode } = useDomainConfig();
    const [{ data, fetching, error }] = useSharedWishlistQueryApi({
        variables: { catnums },
    });
    useQueryError(error, error instanceof CombinedError);
    if (!data?.productsByCatnums) {
        return { products: [], fetching };
    }
    const sharedProducts = [];
    for (const productData of data.productsByCatnums) {
        sharedProducts.push(mapListedProductType(productData, currencyCode));
    }

    return {
        products: sharedProducts,
        fetching,
    };
};
