import { getCookie } from 'cookies-next';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { SocialLoginDataType } from 'types/cookie';
import { UserConsentFormType } from 'types/form';

export const getUserConsentCookie = (ctx?: GetServerSidePropsContext | NextPageContext): UserConsentFormType | null => {
    const userConsentCookieString = getCookie('userConsent', { req: ctx?.req, res: ctx?.res });

    return userConsentCookieString !== undefined ? JSON.parse(decodeURIComponent(userConsentCookieString)) : null;
};

export const getSocialLoginDataCookie = (
    ctx?: GetServerSidePropsContext | NextPageContext,
): SocialLoginDataType | null => {
    const registrationDataCookie = getCookie('registrationDataCookie', { req: ctx?.req, res: ctx?.res });

    return registrationDataCookie ? JSON.parse(decodeURIComponent(registrationDataCookie)) : null;
};
