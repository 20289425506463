import { MapActions } from 'context/Map/types';
import {
    getBalikovnaCookie,
    mapBalikovnaExtendedPoint,
    removeBalikovnaCookie,
    setBalikovnaCookie,
} from 'helpers/balikovna';
import { BalikovnaExtendedPoint } from 'helpers/balikovna/types';
import {
    getPacketeryCookie,
    mapPacketeryExtendedPoint,
    removePacketeryCookie,
    setPacketeryCookie,
} from 'helpers/packetery';
import { PacketeryExtendedPoint } from 'helpers/packetery/types';
import {
    getPaczkomatCookie,
    mapPaczkomatExtendedPoint,
    removePaczkomatCookie,
    setPaczkomatCookie,
} from 'helpers/paczkomat';
import { PaczkomatExtendedPoint } from 'helpers/paczkomat/types';
import {
    getPostOfficeCzCookie,
    getPostOfficeHuCookie,
    getPostOfficePbhCookie,
    getPostOfficeSkCookie,
    removePostOfficeCzCookie,
    removePostOfficeHuCookie,
    removePostOfficePbhCookie,
    removePostOfficeSkCookie,
    setPostOfficeCzCookie,
    setPostOfficeHuCookie,
    setPostOfficePbhCookie,
    setPostOfficeSkCookie,
} from 'helpers/postOffice/postOffice';
import { mapSearchedStoreToPickupPoint } from 'helpers/searchedStore';
import { MapMarkerType } from 'types/map';
import { ExternalPickupPointType, PickupPointType } from 'types/pickupPoint';
import { SearchedStoreType } from 'types/store';
import { MappedTransportType, TransportTypeCodeEnum } from 'types/transport';

export const mapPickupPointFromExternalPickupPoint = (
    point: ExternalPickupPointType,
    openedTransportWithPickupPointsCode: string,
): PickupPointType | null => {
    let mappedPickupPoint = null;
    switch (openedTransportWithPickupPointsCode) {
        case TransportTypeCodeEnum.COMMON:
            mappedPickupPoint = mapSearchedStoreToPickupPoint(point as SearchedStoreType);
            break;
        case TransportTypeCodeEnum.PACKETERY:
            mappedPickupPoint = mapPacketeryExtendedPoint(point as PacketeryExtendedPoint);
            setPacketeryCookie(mappedPickupPoint);
            break;
        case TransportTypeCodeEnum.PACZKOMAT:
            mappedPickupPoint = mapPaczkomatExtendedPoint(point as PaczkomatExtendedPoint);
            setPaczkomatCookie(mappedPickupPoint);
            break;
        case TransportTypeCodeEnum.BALIKOVNA:
            mappedPickupPoint = mapBalikovnaExtendedPoint(point as BalikovnaExtendedPoint);
            setBalikovnaCookie(mappedPickupPoint);
            break;
        case TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_CZ:
            mappedPickupPoint = mapSearchedStoreToPickupPoint(point as SearchedStoreType);
            setPostOfficeCzCookie(mappedPickupPoint);
            break;
        case TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_HU:
            mappedPickupPoint = mapSearchedStoreToPickupPoint(point as SearchedStoreType);
            setPostOfficeHuCookie(mappedPickupPoint);
            break;
        case TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_SK:
            mappedPickupPoint = mapSearchedStoreToPickupPoint(point as SearchedStoreType);
            setPostOfficeSkCookie(mappedPickupPoint);
            break;
        case TransportTypeCodeEnum.PBH:
            mappedPickupPoint = mapSearchedStoreToPickupPoint(point as SearchedStoreType);
            setPostOfficePbhCookie(mappedPickupPoint);
            break;
        default:
            mappedPickupPoint = null;
            break;
    }

    return mappedPickupPoint;
};

export const removeWidgetsCookies = (): void => {
    removePacketeryCookie();
    removePaczkomatCookie();
    removeBalikovnaCookie();
    removePostOfficeCzCookie();
    removePostOfficeHuCookie();
    removePostOfficeSkCookie();
    removePostOfficePbhCookie();
};

export const getIsTransportWithPickupPoint = (
    transportCode: string | null | undefined,
    isPersonalPickup: boolean,
): boolean => {
    if (!transportCode) {
        return false;
    }

    return (
        isPersonalPickup ||
        transportCode === TransportTypeCodeEnum.PACKETERY ||
        transportCode === TransportTypeCodeEnum.PACZKOMAT ||
        transportCode === TransportTypeCodeEnum.BALIKOVNA ||
        transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_CZ ||
        transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_HU ||
        transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_SK ||
        transportCode === TransportTypeCodeEnum.PBH
    );
};

export const getPickupPointDetail = (
    transportItem: MappedTransportType,
    pickupPoint: PickupPointType | null,
): PickupPointType | null => {
    const transportCode = transportItem.transportType.code;

    if (transportCode === TransportTypeCodeEnum.PACKETERY) {
        return getPacketeryCookie();
    }

    if (transportCode === TransportTypeCodeEnum.PACZKOMAT) {
        return getPaczkomatCookie();
    }

    if (transportCode === TransportTypeCodeEnum.BALIKOVNA) {
        return getBalikovnaCookie();
    }

    if (transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_CZ) {
        return getPostOfficeCzCookie();
    }

    if (transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_HU) {
        return getPostOfficeHuCookie();
    }

    if (transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_SK) {
        return getPostOfficeSkCookie();
    }

    if (transportCode === TransportTypeCodeEnum.PBH) {
        return getPostOfficePbhCookie();
    }

    if (transportCode === TransportTypeCodeEnum.COMMON && transportItem.isPersonalPickup) {
        return pickupPoint;
    }

    return null;
};

export const getSelectedPickupPoint = (
    transport: MappedTransportType | null,
    pickupPointIdentifier: string | null | undefined,
): PickupPointType | null => {
    if (transport === null || pickupPointIdentifier === null) {
        return null;
    }
    const transportCode = transport.transportType.code;

    if (transportCode === TransportTypeCodeEnum.PACKETERY) {
        return getPacketeryCookie();
    }

    if (transportCode === TransportTypeCodeEnum.PACZKOMAT) {
        return getPaczkomatCookie();
    }

    if (transportCode === TransportTypeCodeEnum.BALIKOVNA) {
        return getBalikovnaCookie();
    }

    if (transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_CZ) {
        return getPostOfficeCzCookie();
    }

    if (transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_HU) {
        return getPostOfficeHuCookie();
    }

    if (transportCode === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_SK) {
        return getPostOfficeSkCookie();
    }

    if (transportCode === TransportTypeCodeEnum.PBH) {
        return getPostOfficePbhCookie();
    }

    const pickupPoint = transport.stores.find((place) => place.identifier === pickupPointIdentifier);

    return pickupPoint === undefined ? null : pickupPoint;
};

export const centerOnMarkerHandler = (marker: MapMarkerType, dispatch: React.Dispatch<MapActions>) => {
    if (marker.lng && marker.lat) {
        dispatch({
            type: 'setCenter',
            payload: { lng: marker.lng, lat: marker.lat },
        });
        dispatch({
            type: 'setZoom',
            payload: 15,
        });
        dispatch({ type: 'setActiveMarkerUuid', payload: marker.identifier });
    }
};
