import { StaticImageData } from 'next/image';
import TescomaClubKartaEn from 'public/images/tescoma-klub-karta-en.svg';
import TescomaClubKarta from 'public/images/tescoma-klub-karta.svg';

const englishTescomaClubLocales = ['pt', 'de', 'es', 'de-AT'];

export const getTescomaClubCardImage = (defaultLocale: string): StaticImageData => {
    if (englishTescomaClubLocales.includes(defaultLocale)) {
        return TescomaClubKartaEn;
    }

    return TescomaClubKarta;
};

export const getTescomaClubImage = (defaultLocale: string): string => {
    if (englishTescomaClubLocales.includes(defaultLocale)) {
        return '/images/tescoma-club-en.png';
    }

    return '/images/tescoma-club.png';
};
