import { useLatest } from './useLatest';
import { RefObject, useEffect, useState } from 'react';

export function useIntersection(
    ref: RefObject<HTMLElement> | null,
    options?: IntersectionObserverInit,
): IntersectionObserverEntry | null | undefined {
    const [intersectionObserverEntry, setIntersectionObserverEntry] = useState<IntersectionObserverEntry | null>();

    const latestOptions = useLatest(options);

    useEffect(() => {
        const node = ref?.current;

        if (node && typeof IntersectionObserver === 'function') {
            const handler = (entries: IntersectionObserverEntry[]) => {
                setIntersectionObserverEntry(entries[0]);
            };

            const observer = new IntersectionObserver(handler, latestOptions.current);

            observer.observe(node);

            return () => {
                setIntersectionObserverEntry(null);
                observer.disconnect();
            };
        }

        return undefined;
    }, [latestOptions, ref]);

    return intersectionObserverEntry;
}
