import {
    OrderStepsListItemLinkStyled,
    OrderStepsListItemStyled,
    OrderStepsListStyled,
    OrderStepsWrapperStyled,
} from './OrderSteps.style';
import Webline from 'components/Layout/Webline/Webline';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { MenuBasketIcon, MenuUserIcon, TruckIcon } from 'public/svg/IconsSvg';
import { memo } from 'react';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

type OrderStepType = {
    icon: JSX.Element;
    id: number;
    title: string;
    url?: string;
};

type OrderStepsProps = {
    activeStep: number;
    tightTopMargin?: boolean;
    isOrderCreated: boolean;
};

const TEST_IDENTIFIER = 'blocks-ordersteps-';

const OrderSteps = memo<OrderStepsProps>((props) => {
    const { activeStep, tightTopMargin, isOrderCreated } = props;

    const { url: domainUrl } = useDomainConfig();

    const t = useTypedTranslationFunction();
    const [cartUrl, transportAndPaymentUrl] = getInternationalizedStaticUrls(
        ['/cart', '/order/transport-and-payment'],
        domainUrl,
    );

    const orderFlow: OrderStepType[] = [
        {
            icon: <MenuBasketIcon className="h-5 w-5 stroke-black" />,
            id: 1,
            title: t('Cart'),
            url: cartUrl,
        },
        {
            icon: <TruckIcon />,
            id: 2,
            title: t('Transport and payment'),
            url: transportAndPaymentUrl,
        },
        {
            icon: <MenuUserIcon className="h-5 w-5 stroke-black" />,
            id: 3,
            title: t('Contact information'),
        },
    ];

    const createdOrder: OrderStepType[] = [
        {
            icon: <MenuBasketIcon className="h-5 w-5 stroke-black" />,
            id: 1,
            title: t('Payment'),
        },
        {
            icon: <TruckIcon />,
            id: 2,
            title: t('Shipper'),
        },
        {
            icon: <MenuUserIcon className="h-5 w-5 stroke-black" />,
            id: 3,
            title: t('Delivered'),
        },
    ];

    const steps = isOrderCreated ? createdOrder : orderFlow;

    const orderStepsBlock = (
        <OrderStepsWrapperStyled $tightTopMargin={tightTopMargin} $negativeMargin={isOrderCreated}>
            <OrderStepsListStyled>
                {steps.map((step) => (
                    <OrderStepsListItemStyled data-testid={TEST_IDENTIFIER + step.id} key={step.id}>
                        {activeStep > step.id && step.url !== undefined ? (
                            <OrderStepsListItemLinkStyled href={step.url} type="static">
                                <>
                                    {step.icon}
                                    {step.title}
                                </>
                            </OrderStepsListItemLinkStyled>
                        ) : (
                            <OrderStepsListItemLinkStyled
                                as="a"
                                $active={activeStep === step.id}
                                $disabled={activeStep < step.id}
                            >
                                {step.icon}
                                {step.title}
                            </OrderStepsListItemLinkStyled>
                        )}
                    </OrderStepsListItemStyled>
                ))}
            </OrderStepsListStyled>
        </OrderStepsWrapperStyled>
    );

    if (isOrderCreated) {
        return orderStepsBlock;
    }

    return <Webline removePaddingOnMobile>{orderStepsBlock}</Webline>;
});

OrderSteps.displayName = 'OrderSteps';

export default OrderSteps;
