import * as React from 'react';
import twMerge from 'utils/twMerge';

export const ArrowIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m23.254 6.2185-11.254 11.254-11.254-11.254"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth="2.1101"
        />
    </svg>
);

export const ArrowRightIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M501.396 236.195l-183.095-183.1C313.074 47.87 306.108 45 298.68 45c-7.437 0-14.399 2.873-19.625 8.095l-16.624 16.628c-5.222 5.219-8.1 12.189-8.1 19.62 0 7.428 2.878 14.633 8.1 19.852l106.816 107.05H29.89c-15.3 0-27.39 11.978-27.39 27.283v23.507c0 15.305 12.09 28.491 27.39 28.491h340.57L262.435 403.174c-5.222 5.227-8.1 12.007-8.1 19.439 0 7.423 2.878 14.303 8.1 19.525l16.624 16.575c5.227 5.226 12.189 8.075 19.624 8.075 7.428 0 14.394-2.886 19.62-8.112L501.4 275.58c5.24-5.243 8.12-12.242 8.1-19.682.016-7.465-2.86-14.468-8.104-19.703z"
            fill="currentColor"
        />
    </svg>
);

export const ArrowUpIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m23.254 17.782-11.254-11.254-11.254 11.254"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth="2.1101"
        />
    </svg>
);
export const ChevronRightIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 16 28" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m2 26 12-12L2 2"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CartIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CrossIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M509.5 256c0 140.004-113.496 253.5-253.5 253.5C115.996 509.5 2.5 396.004 2.5 256 2.5 115.996 115.996 2.5 256 2.5c140.004 0 253.5 113.496 253.5 253.5z"
            overflow="visible"
            fill="currentColor"
        />
        <path
            d="M163.248 123.419l-39.83 39.829L216.172 256l-92.752 92.752 39.829 39.83L256 295.828l92.752 92.752 39.83-39.829L295.828 256l92.752-92.752-39.829-39.83L256 216.172z"
            color="#000"
            overflow="visible"
            fill="#fff"
        />
    </svg>
);

export const SearchIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M225.772 2.5C102.663 2.5 2.5 102.663 2.5 225.772c0 123.116 100.163 223.272 223.272 223.272 123.116 0 223.272-100.156 223.272-223.272C449.044 102.663 348.888 2.5 225.772 2.5zm0 405.326c-100.383 0-182.053-81.67-182.053-182.053S125.39 43.719 225.772 43.719s182.053 81.67 182.053 182.053-81.67 182.054-182.053 182.054z"
            fill="currentColor"
        />
        <path
            d="M503.461 474.319L385.3 356.156c-8.052-8.051-21.091-8.051-29.143 0-8.051 8.045-8.051 21.098 0 29.143L474.32 503.46a20.538 20.538 0 0 0 14.571 6.039 20.551 20.551 0 0 0 14.571-6.039c8.052-8.044 8.052-21.098 0-29.142z"
            fill="currentColor"
        />
    </svg>
);

export const ChatIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M244.208.496c37.877-.09 75.223 8.779 109.04 25.84 82.575 41.269 134.844 125.767 134.88 218.08.078 32.778-9.529 64.314-22.366 94.358l46.52 139.615a26.947 26.947 0 01-34.1 34.102L338.564 465.97c-30.059 12.844-61.614 22.451-94.41 22.366-92.253-.057-176.694-52.277-217.975-134.775v-.053C9.092 319.66.19 282.278.288 244.362v-12.734a26.947 26.947 0 01.054-1.474C7.163 106.498 106.29 7.372 229.946.55a26.947 26.947 0 011.474-.053h12.735zm.052 53.889a26.947 26.947 0 01-.052 0h-11.473C136.24 59.82 59.613 136.448 54.177 232.944v11.472a26.947 26.947 0 010 .052 186.993 186.993 0 0020.103 84.78 26.947 26.947 0 01.053.105c32.196 64.422 97.855 105.066 169.875 105.094a26.947 26.947 0 01.052 0 186.978 186.978 0 0084.78-20.103 26.947 26.947 0 0120.682-1.473l94.358 31.417-31.418-94.358a26.947 26.947 0 011.474-20.682 186.977 186.977 0 0020.103-84.78 26.947 26.947 0 010-.052c-.028-72.02-40.672-137.679-105.094-169.876a26.947 26.947 0 01-.105-.052 186.982 186.982 0 00-84.78-20.103z"
            overflow="visible"
            fill="currentColor"
        />
    </svg>
);

export const MarkerIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M256.01.321C138.305.321 42.711 96.981 44 214.658c.062 83.38 51.484 155.077 100.261 206.563 48.807 51.518 97.687 84.022 97.687 84.022a25.353 25.353 0 0028.123 0s48.886-32.504 97.686-84.022c48.771-51.487 100.2-123.185 100.262-206.563C469.307 96.98 373.713.32 256.009.32zm0 50.7c90.145 0 162.36 73.052 161.309 163.191a25.353 25.353 0 000 .297c0 61.712-41.823 124.801-86.349 171.806-37.49 39.579-64.064 56.968-74.96 64.613-10.901-7.647-37.418-25.037-74.912-64.613C136.566 339.31 94.7 276.22 94.7 214.51a25.353 25.353 0 000-.297c-1.05-90.139 71.164-163.19 161.31-163.19z"
            overflow="visible"
            fill="currentColor"
        />

        <path
            d="M256.01 126.923c-48.072 0-87.587 39.515-87.587 87.586 0 48.072 39.515 87.587 87.586 87.587 48.072 0 87.587-39.515 87.587-87.587 0-48.07-39.515-87.586-87.587-87.586zm0 50.7c20.67 0 36.886 16.216 36.886 36.886 0 20.671-16.216 36.887-36.887 36.887-20.67 0-36.886-16.216-36.886-36.887 0-20.67 16.216-36.886 36.886-36.886z"
            overflow="visible"
            fill="currentColor"
        />
    </svg>
);

export const UserIcon: FC<{}> = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.6 12c0 2.563.927 4.91 2.464 6.722C6.108 16.507 8.474 14.8 12 14.8c3.54 0 5.817 1.61 7.936 3.921A10.359 10.359 0 0 0 22.4 12c0-5.744-4.656-10.4-10.4-10.4C6.256 1.6 1.6 6.256 1.6 12ZM12 22.4a10.36 10.36 0 0 1-6.81-2.539c1.913-2.088 3.895-3.46 6.81-3.46 2.931 0 4.824 1.279 6.81 3.46A10.36 10.36 0 0 1 12 22.4ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm2.4 8v1.6a2.4 2.4 0 1 1-4.8 0V8a2.4 2.4 0 0 1 4.8 0ZM8 8a4 4 0 1 1 8 0v1.6a4 4 0 1 1-8 0V8Z"
            fill="currentColor"
        />
    </svg>
);

export const CloseIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M302.449 255.999L499.864 58.577c12.848-12.842 12.848-33.604 0-46.446-12.841-12.841-33.604-12.841-46.445 0L255.997 209.553 58.581 12.13C45.734-.71 24.977-.71 12.136 12.131c-12.848 12.842-12.848 33.604 0 46.446L209.55 255.998 12.136 453.42c-12.848 12.842-12.848 33.604 0 46.446 6.4 6.406 14.814 9.623 23.222 9.623a32.756 32.756 0 0023.223-9.623l197.416-197.422 197.422 197.422a32.756 32.756 0 0023.223 9.623 32.756 32.756 0 0023.222-9.623c12.848-12.842 12.848-33.604 0-46.446zm0 0"
            fill="currentColor"
        />
    </svg>
);

export const MenuIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 10H17.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.5 5H17.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.5 15H17.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const RemoveIcon: FC<{ onClick?: (e: React.MouseEvent) => void }> = ({ className, onClick }) => (
    <svg
        className={twMerge('icon', className)}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <g>
            <path
                d="M456.051 2.504L2.503 456.053l53.445 53.445L509.497 55.95z"
                overflow="visible"
                fill="currentColor"
            />
            <path d="M55.948 2.504L2.503 55.949 456.05 509.5l53.446-53.446z" overflow="visible" fill="currentColor" />
        </g>
    </svg>
);

export const NotImplementedYetIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M256 512c-68.38 0-132.667-26.629-181.02-74.98C26.629 388.667 0 324.38 0 256S26.629 123.333 74.98 74.98C123.333 26.629 187.62 0 256 0s132.667 26.629 181.02 74.98C485.371 123.333 512 187.62 512 256s-26.629 132.667-74.98 181.02C388.667 485.371 324.38 512 256 512zm0-472C136.897 40 40 136.897 40 256s96.897 216 216 216 216-96.897 216-216S375.103 40 256 40zm93.737 260.188c-9.319-5.931-21.681-3.184-27.61 6.136-.247.387-25.137 38.737-67.127 38.737s-66.88-38.35-67.127-38.737c-5.93-9.319-18.291-12.066-27.61-6.136s-12.066 18.292-6.136 27.61c1.488 2.338 37.172 57.263 100.873 57.263s99.385-54.924 100.873-57.263c5.93-9.319 3.183-21.68-6.136-27.61zM168 165c13.807 0 25 11.193 25 25s-11.193 25-25 25-25-11.193-25-25 11.193-25 25-25zm150 25c0 13.807 11.193 25 25 25s25-11.193 25-25-11.193-25-25-25-25 11.193-25 25z"
            fill="currentColor"
        />
    </svg>
);

export const TriangleIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="m84.125 188.612 252.164-171.93C373.509-8.695 424.253.905 449.63 38.125a81.566 81.566 0 0 1 14.174 45.949v343.86c0 45.048-36.518 81.566-81.566 81.566a81.568 81.568 0 0 1-45.95-14.174L84.126 323.396c-37.22-25.377-46.82-76.122-21.443-113.341a81.57 81.57 0 0 1 21.443-21.443z"
            fill="currentColor"
        />
    </svg>
);

export const SortIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m1.505 11.195 2.828-2.828 2.829 2.828M14.495 4.805l-2.828 2.828-2.829-2.828M11.667 7.633v-6.6M4.333 14.966v-6.6"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const RemoveBoldIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M489.846 394.952L350.899 256.005l138.947-138.947c26.204-26.204 26.204-68.69 0-94.894-26.204-26.204-68.69-26.204-94.894 0L255.994 161.11 117.047 22.153c-26.215-26.204-68.702-26.204-94.894 0-26.204 26.214-26.204 68.701 0 94.894L161.11 255.994 22.153 394.94c-26.204 26.214-26.204 68.701 0 94.894 13.118 13.107 30.274 19.65 47.452 19.65 17.167 0 34.346-6.554 47.453-19.65l138.936-138.936 138.958 138.947c13.118 13.096 30.274 19.65 47.452 19.65 17.179 0 34.346-6.554 47.453-19.65 26.193-26.204 26.193-68.68-.011-94.894z"
            fill="currentColor"
        />
    </svg>
);

export const RemoveThinIcon: FC<{ onClick?: () => void }> = ({ className, onClick }) => (
    <svg
        className={twMerge('icon', className)}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <path
            d="M509.5 32.372L479.628 2.5 256 226.128 32.372 2.5 2.5 32.372 226.128 256 2.5 479.628 32.372 509.5 256 285.872 479.628 509.5l29.872-29.872L285.872 256z"
            fill="currentColor"
        />
    </svg>
);

export const PlusIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="m10 1v18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path d="m19 10h-18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
);

export const MinusIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="m19 10h-18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
);

export const FilterIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g transform="matrix(23.04546 0 0 23.04545 -9169.492 -192542.19)">
            <g stroke="none">
                <path d="M401.5 8357.5h1v7h-1zm0 10h1v7h-1zm7-2h1v9h-1zm0-8h1v5h-1z" fill="currentColor" />
                <path d="M412.5 8361.5v1h-7v-1zm7 8v1h-7v-1zm-14-2v1h-7v-1zm10-10h1v9h-1z" fill="currentColor" />
                <path d="M415.5 8369.5h1v5h-1z" fill="currentColor" />
            </g>
        </g>
    </svg>
);

export const PhoneIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M73.698 2.5c-41.49-.077-74.72 36.314-70.901 77.635a25.353 25.353 0 0 0 0 .346c7.961 74.689 33.436 146.425 74.317 209.435.02.03.03.069.05.1.012.018.037.03.05.049a472.057 472.057 0 0 0 145.118 144.871c.013.009.037-.008.05 0a480.098 480.098 0 0 0 209.039 74.219 25.353 25.353 0 0 0 .445.05c41.33 3.686 77.661-29.557 77.635-71.05v-68.673a25.353 25.353 0 0 0-6.14-16.438c-6.844-27.567-25.942-51.047-55.156-55.156a25.353 25.353 0 0 0-.198 0c-20.099-2.644-39.869-7.59-58.87-14.656h-.098c-25.934-9.696-55.337-3.446-75.06 15.992a25.353 25.353 0 0 0-.1.1l-13.665 13.615c-40.9-26.304-75.362-60.7-101.746-101.549l13.566-13.566a25.353 25.353 0 0 0 .148-.099c19.508-19.704 25.784-49.157 15.993-75.11a269.681 269.681 0 0 1-14.656-58.72 25.353 25.353 0 0 0-.05-.248c-5.022-35.17-35.672-61.504-71.197-61.147H73.697zm-.1 50.7a25.353 25.353 0 0 0 .1 0h68.821a25.353 25.353 0 0 0 .248 0c10.492-.105 19.064 7.24 20.547 17.626a320.18 320.18 0 0 0 17.379 69.614 25.353 25.353 0 0 0 .05.1c2.826 7.492 1.055 15.741-4.605 21.487l-29.064 29.014a25.353 25.353 0 0 0-4.11 30.5 392.043 392.043 0 0 0 147.15 146.901 25.353 25.353 0 0 0 30.45-4.11l29.063-29.013c5.763-5.651 14.127-7.444 21.686-4.605a25.353 25.353 0 0 0 .05 0 320.538 320.538 0 0 0 69.811 17.379c10.54 1.482 17.902 10.215 17.627 20.745a25.353 25.353 0 0 0 0 .644v68.722c.008 12.449-9.947 21.488-22.33 20.449-66.505-7.27-130.39-29.9-186.56-66.247a25.353 25.353 0 0 0-.199-.149c-52.27-33.146-96.627-77.363-129.87-129.572a25.353 25.353 0 0 0-.098-.198C83.194 206.199 60.452 142.06 53.299 75.332 52.257 63.048 61.24 53.168 73.6 53.2z"
            fill="currentColor"
        />
    </svg>
);

export const FacebookIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} fill="none" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m396.8 0h-76.801c-33.948 0-66.505 13.486-90.51 37.49-24.005 24.005-37.49 56.562-37.49 90.51v76.8h-76.8v102.4h76.8v204.8h102.4v-204.8h76.8l25.601-102.4h-102.4v-76.8c0-6.7896 2.6972-13.301 7.4981-18.102 4.8009-4.8009 11.312-7.4981 18.102-7.4981h76.801z"
            fill="currentColor"
            strokeWidth="24.576"
        />
    </svg>
);

export const YoutubeIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.4791 6.68758C23.3554 6.19322 23.1034 5.74026 22.7485 5.37446C22.3937 5.00867 21.9486 4.74298 21.4583 4.60425C19.6666 4.16675 12.4999 4.16675 12.4999 4.16675C12.4999 4.16675 5.33328 4.16675 3.54161 4.64591C3.05124 4.78465 2.60617 5.05033 2.25135 5.41613C1.89652 5.78193 1.64452 6.23488 1.52078 6.72925C1.19288 8.54753 1.03248 10.3921 1.04161 12.2397C1.02992 14.1012 1.19033 15.9598 1.52078 17.7917C1.65719 18.2708 1.91485 18.7065 2.26884 19.0568C2.62284 19.4072 3.06122 19.6603 3.54161 19.7917C5.33328 20.2709 12.4999 20.2709 12.4999 20.2709C12.4999 20.2709 19.6666 20.2709 21.4583 19.7917C21.9486 19.653 22.3937 19.3873 22.7485 19.0215C23.1034 18.6557 23.3554 18.2028 23.4791 17.7084C23.8045 15.9038 23.9649 14.0733 23.9583 12.2397C23.97 10.3782 23.8096 8.51956 23.4791 6.68758V6.68758Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M10.1562 15.6458L16.1458 12.2395L10.1562 8.83325V15.6458Z" fill="currentColor" />
    </svg>
);

export const InstagramIcon: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        version="1.1"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <radialGradient id="instagram-gradient" r="150%" cx="30%" cy="107%">
                <stop stopColor="#fdf497" offset="0" />
                <stop stopColor="#fd5949" offset="0.3" />
                <stop stopColor="#d6249f" offset="0.6" />
                <stop stopColor="#285AEB" offset="0.8" />
            </radialGradient>
        </defs>
        <g strokeWidth="46.546" stroke="url(#instagram-gradient)">
            <rect x="23.273" y="23.273" width="465.45" height="465.45" rx="116.36" ry="116.36" />
            <path d="m349.09 241.34a93.091 93.091 0 1 1-78.429-78.429 93.091 93.091 0 0 1 78.429 78.429z" />
            <line x1="384" x2="384" y1="128" y2="128" stroke="#3457E6" />
        </g>
    </svg>
);

export const MapMarkerTescomaStoreIcon: FC<{ onClick?: () => void }> = ({ className, onClick }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 26.8 33.3"
        viewBox="0 0 26.8 33.3"
        onClick={onClick}
    >
        <path
            d="M24.4 1.7H3.3c-.9 0-1.7.7-1.7 1.7v21.1c0 .9.7 1.7 1.7 1.7h4.9l5.6 6.6 5.6-6.6h4.9c.9 0 1.7-.7 1.7-1.7V3.3c.1-.9-.7-1.6-1.6-1.6z"
            fill="#87888a"
        />
        <path
            d="M23.5.6H2.4C1.5.6.7 1.3.7 2.3v21.1c0 .9.7 1.7 1.7 1.7h4.9l5.6 6.6 5.6-6.6h4.9c.9 0 1.7-.7 1.7-1.7V2.3c.1-.9-.7-1.7-1.6-1.7z"
            fill="#da0000"
        />
        <path
            d="m16.1 18.4-2 .3c-.7 0-1.1-.2-1.3-.6a2 2 0 0 1 0-1.1l1.6-6h3.8l.1-.1.7-2.6-.1-.1h-3.7l.9-3.6-.1-.1h-4.3l-.1.1-1 3.6H9.1l-.1.1-.7 2.5.1.1h1.4a344 344 0 0 0-1.7 6.3c-.3 1.1-.3 2.1 0 2.7.5 1 1.8 1.5 3.5 1.5 0 0 1.7.1 3.8-.3l.1-.1.7-2.6h-.1z"
            fill="#fff"
        />
    </svg>
);

export const MapMarkerPinIcon: FC<{ onClick?: () => void }> = ({ className, onClick }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 22.7 28.9"
        viewBox="0 0 22.7 28.9"
        onClick={onClick}
    >
        <path
            d="M11.9 2a10.3 10.3 0 0 0-8.1 16.6l.1.1.3.4 7.6 9 7.6-8.9.4-.4.1-.1c1.5-1.8 2.2-4 2.2-6.4C22.1 6.6 17.5 2 11.9 2z"
            fill="#87888a"
        />
        <path
            d="M11 1.1C5.4 1.1.8 5.7.8 11.4c0 2.3.8 4.5 2.2 6.4l.1.1.3.4 7.6 9 7.6-8.9.4-.4.1-.1c1.5-1.8 2.2-4 2.2-6.4 0-5.8-4.6-10.4-10.3-10.4z"
            fill="#da0000"
        />
    </svg>
);

export const MapMarkerClusterIcon: FC<{ width?: number; height?: number }> = ({ className, width, height }) => (
    <svg
        className={className}
        style={{
            width: width,
            height: height,
        }}
        xmlns="http://www.w3.org/2000/svg"
        enableBackground=":new 0 0 27.4 22.5"
        viewBox="0 0 27.4 22.5"
    >
        <path
            d="M24.9 2.3H4c-.9 0-1.7.7-1.7 1.5v16.5c0 .9.8 1.5 1.7 1.5h20.9c.9 0 1.7-.7 1.7-1.5V3.8c0-.8-.8-1.5-1.7-1.5z"
            fill="#87888a"
        />
        <path
            d="M23.5 1H2.6C1.7 1 1 1.7 1 2.5V19c0 .9.8 1.5 1.7 1.5h20.9c.9 0 1.7-.7 1.7-1.5V2.5c-.1-.8-.8-1.5-1.8-1.5z"
            fill="#da0000"
        />
    </svg>
);

export const CheckmarkIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="circle" cx="26" cy="26" r="25" fill="none" />
        <path className="check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
);

export const WarningIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.158 3.475c.37-.633 1.305-.633 1.684 0l9.029 15.109c.37.632-.098 1.416-.848 1.416H2.977c-.75 0-1.218-.784-.848-1.416ZM13 15h-2v2h2v-2Zm-1-6c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1Z"
            fill="currentColor"
        />
    </svg>
);

export const InfoIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M494.29 256c0 131.604-106.686 238.29-238.29 238.29-131.604 0-238.29-106.686-238.29-238.29C17.71 124.397 124.396 17.71 256 17.71c131.604 0 238.29 106.686 238.29 238.29z"
            fill="#fff"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="30.42"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M293.913 349.123H275.5V220.239c0-1.43-.161-2.822-.472-4.158-1.883-8.166-9.202-14.254-17.938-14.254h-36.825c-10.169 0-18.412 8.243-18.412 18.412 0 10.168 8.243 18.412 18.412 18.412h18.412v110.472h-18.412c-10.169 0-18.412 8.243-18.412 18.413 0 10.166 8.243 18.41 18.412 18.41h73.648c10.17 0 18.41-8.244 18.41-18.41 0-10.17-8.24-18.413-18.41-18.413z"
            fill="currentColor"
        />
        <path
            d="M256 134.318h.369"
            stroke="currentColor"
            strokeWidth="45.63"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ShowIcon: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
        <circle cx="12" cy="12" r="3" />
    </svg>
);

export const HideIcon: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
        <line x1="1" x2="23" y1="1" y2="23" />
    </svg>
);

export const HeartIcon: FC = ({ className }) => (
    <svg className={twMerge('icon h-6 w-6', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
            d="M20.8 4.5c-.5-.5-1.1-.9-1.8-1.2-.7-.3-1.4-.4-2.1-.4s-1.4.1-2.1.4c-.7.3-1.3.7-1.8 1.2l-1.1 1.1-1.1-1.1c-1-1-2.4-1.6-3.9-1.6S4 3.5 3 4.5 1.4 6.9 1.4 8.4 2 11.3 3 12.3l1.1 1.1 7.8 7.8 7.8-7.8 1.1-1.1c.5-.5.9-1.1 1.2-1.8.3-.7.4-1.4.4-2.1S22.3 7 22 6.3c-.3-.7-.7-1.3-1.2-1.8h0Z"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const HeartFullIcon: FC = ({ className }) => (
    <svg className={twMerge('icon h-6 w-6', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
            d="M20.8 4.5c-.5-.5-1.1-.9-1.8-1.2-.7-.3-1.4-.4-2.1-.4s-1.4.1-2.1.4c-.7.3-1.3.7-1.8 1.2l-1.1 1.1-1.1-1.1c-1-1-2.4-1.6-3.9-1.6S4 3.5 3 4.5 1.4 6.9 1.4 8.4 2 11.3 3 12.3l1.1 1.1 7.8 7.8 7.8-7.8 1.1-1.1c.5-.5.9-1.1 1.2-1.8.3-.7.4-1.4.4-2.1S22.3 7 22 6.3c-.3-.7-.7-1.3-1.2-1.8Z"
            fill="currentColor"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
        />
    </svg>
);

export const HomeIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.5 5 6 1.5 10.5 5v5.5a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V5Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const EnvelopeIcon: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m69.818 69.818h372.36c25.6 0 46.545 20.945 46.545 46.545v279.27c0 25.6-20.945 46.545-46.545 46.545h-372.36c-25.6 0-46.545-20.945-46.545-46.545v-279.27c0-25.6 20.945-46.545 46.545-46.545z"
            strokeWidth="46.546"
        />
        <polyline transform="matrix(23.273 0 0 23.273 -23.273 -23.273)" points="22 6 12 13 2 6" />
    </svg>
);

export const ChevronIcon: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <polyline transform="matrix(42.667 0 0 42.667 -256 -106.67)" points="17 11 12 6 7 11" />
    </svg>
);

export const CheckIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.204.001c-4.772 2.945-8.21 6.665-9.607 8.318L2.012 5.491C1.917 5.422-.051 7.18 0 7.236l6.53 6.685a.24.24 0 0 0 .185.079c.013 0 .033 0 .05-.004a.26.26 0 0 0 .19-.164c1.052-2.707 4.525-8.377 8.96-12.488a.266.266 0 0 0 .063-.296S15.27-.04 15.204.001Z"
            fill="currentColor"
        />
    </svg>
);

export const TescomaClubIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="17" height="17" rx="8.5" fill="#bd0d2e" />
        <path
            d="M9.08 11.486c-.632-.064-.6-.63-.516-.982.084-.353.832-3.023.832-3.023h2.235L12 5.965H9.734s.516-1.954.506-1.944C10.23 4.032 7.91 4 7.91 4l-.548 1.976h-.875l-.39 1.473.886.011s-.654 2.552-.76 2.873c-.105.32-.379 1.068-.105 1.826s1.191.78 2.119.833c.938.053 1.96-.171 1.96-.171l.39-1.516h-.22c-.496.17-.844.224-1.287.181Z"
            fill="#fff"
        />
    </svg>
);

export const TescomaClubUniversal: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path
            fill="#000"
            d="M32 13a19 19 0 1 0 0 38 19 19 0 0 0 0-38Zm10 11.7-1.1 3.9-.2.1h-5.9l-2.5 9.5c-.2.7-.2 1.3 0 1.7.3.6 1 1 2.1.9 0 0 1.4 0 3.1-.4 0 0 .2 0 .2.2l-1.1 4.1-.1.1c-3.2.6-5.9.5-5.9.5-2.6 0-4.6-.8-5.4-2.4-.5-1-.5-2.6 0-4.3l2.7-9.9h-2.2s-.2 0-.2-.2l1.1-3.9.2-.1h2.3l1.5-5.6.2-.1h6.7s.2 0 .2.2l-1.5 5.6H42s.2 0 .2.2Z"
        />
    </svg>
);

export const TescomaClubIconCzech: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
        <path
            fill="#000"
            d="M10.8 21.8a10.2 10.2 0 1 0 0 20.4 10.2 10.2 0 0 0 0-20.4Zm5.4 6.3-.6 2.1h-3.2L11 35.3v.9c.2.3.5.5 1.1.5l1.7-.2-.6 2.2c-1.7.3-3.2.2-3.2.2-1.4 0-2.5-.4-2.9-1.3-.3-.6-.3-1.4 0-2.3L8.5 30H7.3l.6-2.1h1.2l.8-3h3.6l-.8 3h3.1Zm20.9-4.5c.3.5.5 1.2.5 2.1l-.5 5.2c-.4 2.9-.5 6-.5 7.8v1.6c-1 0-2.4.1-2.4-2 0-5 1.3-14 1-15.8.9.2 1.5.6 1.9 1.1Zm10.7 8a53 53 0 0 0-.4 5.4v3.6c-1 0-2.4.1-2.4-2 0-.4 0-2.2.2-3.3a9.5 9.5 0 0 1-1.7 3.5c-.8.9-1.5 1.4-2.2 1.4-1.4 0-2.1-1-2.1-3s0-1.6.3-2.8l.4-2.7.1-1.9c1 0 2.5-.1 2.4 2l-.4 2.4c-.3 1.2-.4 2.1-.4 2.8 0 .9.2 1.3.7 1.3.2 0 .5-.2.8-.5l.9-1.3.7-2.1.4-2.5v-2.2c1.3-.2 2.6.1 2.4 1.8Zm10.3-.8c-.6-.7-1.2-1.1-2-1.1s-1.5.4-2.2 1.1c-.7.8-1.2 1.8-1.6 3.1 0-1 .2-2.8.5-4.5l.3-3.7c0-.7-.2-1.4-.5-2-.3-.6-.9-1-1.8-1.2.1 2.4-.9 10.1-.9 14.2s.6 3.4 2.3 3.8c2 .4 4-.7 5.2-2.2.9-1.3 1.4-2.8 1.4-4.6s-.3-2.1-.8-2.8Zm-2.6 6.7c-.3.6-.6 1.1-1 1.5-.7.7-2.1 1.3-2.2 0-.2-1.8.4-4.3 1-5.5.6-1.2 1.2-1.9 1.8-1.9s.7.2.9.7c.2.4.3 1 .3 1.6 0 1.2-.3 2.4-.8 3.6ZM30 35.1l-.2-.7c.8-.3 1.3-.7 1.8-1.2.4-.5.6-1.2.6-1.9s-.2-1.3-.7-1.8c-.4-.5-1-.7-1.6-.7s-.7 0-1 .2c-1.3.6-2.2 2.2-2.7 4.7 0-.9.2-2.3.5-4.2l.4-3.8a4 4 0 0 0-.5-2.1c-.3-.5-1-.9-1.9-1.1.3 1.8-1 10.8-1 15.8 0 2.1 1.4 2 2.4 2 0-1.8.2-3.9.4-5.3h1.2c.7 1.9 1.2 3.2 1.6 3.9.9 1.8 2 2.7 3.2 2.7s.7 0 1.2-.2a13 13 0 0 1-1.9-2.5c-.8-1.2-1.3-2.5-1.7-3.7ZM28.3 34c-.6.3-1.2.3-1.7.3v-.4c.3-1.1.7-2 1.2-2.7.5-.6.9-1 1.4-1 .3 0 .5.1.6.3l.2.8c0 .7-.2 1.3-.7 1.9-.2.3-.6.5-1 .7Zm33.4 3.4c-.9 0-1.7.7-1.7 1.7s.7 1.7 1.7 1.7 1.7-.7 1.7-1.7-.7-1.7-1.7-1.7Z"
        />
    </svg>
);

export const TescomaLogo: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
        <path fill="#000" d="M.5 23.5v17h63.1v-17H.5Z" />
        <path
            fill="#fff"
            d="m9.6 33.9-1 .1c-.3 0-.5 0-.6-.3v-.5l.8-2.9h1.8l.3-1.2H9.1l.5-1.7H7.5L7 29.1h-.7L6 30.3h.7l-.8 3.1v1.3c.2.5.9.7 1.7.7l1.8-.1.3-1.3Zm26.9-5.1c-2 0-3.9 1.1-4.4 3.2-.5 1.9.5 3.3 2.8 3.4 2 0 3.9-1.1 4.4-3.2.5-1.9-.5-3.3-2.8-3.4Zm.5 3.3c-.6 2.1-1.9 1.9-1.9 1.9s-1.4 0-.8-2c.6-2.1 1.9-1.9 1.9-1.9s1.4 0 .8 2Zm22.3 1c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1 1.1-.5 1.1-1.1-.5-1.1-1.1-1.1Zm.5 1.8h-.2l-.4-.5H59v.5h-.1v-1.4h.4c.2 0 .4.2.4.4s-.1.3-.3.4l.4.5Zm-27.7-5.6c-1.1-.5-2.4-.5-2.4-.5-2 0-3.9 1.1-4.4 3.2-.5 1.9.5 3.3 2.8 3.4 0 0 1.4 0 2.6-.4l.4-1.5c-1.6.8-2.6.6-2.6.6s-1.5 0-.9-2c.5-1.6 1.9-1.8 1.9-1.8s1.3-.3 2.2.6l.4-1.5Zm18.7.3c-.4-.9-2.1-.9-3.8-.1l-.3.2c-.4-.9-2.1-.9-3.7-.2v-.4h-2.1l-1.6 6.1h2.1l1.2-4.5.3-.1c.7-.3 1.4-.3 1.6 0v.5l-1.1 4h2.1l1.2-4.5.3-.1c.7-.3 1.4-.3 1.6 0v.5l-1.1 4h2.1l1.1-4.3v-1.3Zm8.7 4.3-.2-.2H59v.5h.3l.2-.2Zm-1.4-4.2s-.4-.9-2.8-.9c0 0-1.3 0-2.6.3l-.4 1.4c1.8-.7 3.3-.5 3.5 0v.6c-1.5 0-4.4.3-4.9 2.3 0 0-.6 2 1.7 2s1.7-.3 2.3-.7v.5h-.1 2l1.1-4.1c.1-.5.2-1 0-1.4Zm-2.9 3.9c-.3.2-.8.4-1.3.4-1 0-.8-.7-.8-.7s0-1 2.4-1l-.4 1.3ZM25 29.2c-.5-.2-1.2-.4-2.2-.4-1.9 0-3.5.7-3.8 2.1-.3 1.5 1.3 1.8 2.3 2 .9.2.8.5.8.5s0 .6-1.4.6-1.9-.4-2.3-.7l-.4 1.5c.4.2 1.2.5 2.5.5 1.9 0 3.5-.7 3.8-2.1.3-1.5-1.3-1.8-2.3-2-.9-.2-.8-.5-.8-.5s0-.6 1.4-.6 1.5.3 2 .6l.4-1.4Zm-10-.4a4.5 4.5 0 0 0-4.4 3.2c-.5 1.9.6 3.3 2.8 3.4 0 0 1.5.1 3.3-.5l.4-1.5c-2.2 1-3.4.7-3.4.7s-1.3-.1-1-1.6h4.9v-.3c.5-2-.4-3.4-2.7-3.4Zm.8 2.5H13c.5-1.3 1.7-1.2 1.7-1.2s1.3 0 1.1 1.2Z"
        />
    </svg>
);

export const SimpleCheckmarkIcon: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8637 3.46967C14.1565 3.76256 14.1565 4.23744 13.8637 4.53033L6.53032 11.8637C6.23743 12.1566 5.76255 12.1566 5.46966 11.8637L2.13633 8.53033C1.84343 8.23744 1.84343 7.76256 2.13633 7.46967C2.42922 7.17678 2.90409 7.17678 3.19699 7.46967L5.99999 10.2727L12.803 3.46967C13.0959 3.17678 13.5708 3.17678 13.8637 3.46967Z"
            fill="currentcolor"
        />
    </svg>
);

export const TruckIcon: FC = ({ className }) => (
    <svg
        className={twMerge("w-6 h-6 stroke-black", className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit={10}
    >
        <circle cx="6.1" cy="17" r="1.9" className="fill-none" />
        <circle cx="18.3" cy="17" r="1.9" className="fill-none" />
        <path d="M13.4 5.2H3.3c-.8 0-1.4.6-1.4 1.4v8.5c0 .8.6 1.4 1.4 1.4h.9c.2-.8.9-1.4 1.8-1.4s1.6.6 1.8 1.4h5.5c.8 0 1.4-.6 1.4-1.4V6.6c0-.8-.6-1.4-1.4-1.4Z" />
        <path d="M22.1 12.6 19 8.8c-.3-.5-.8-.7-1.3-.7h-1.3c-.9 0-1.7.7-1.7 1.7V15c0 .9.7 1.7 1.7 1.7.2-.8.9-1.4 1.8-1.4s1.6.6 1.8 1.4h.5c.9 0 1.7-.7 1.7-1.7v-1.6c0-.2 0-.5-.2-.7Z" />
    </svg>
);

export const FileIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 2v6h6M16 13H8M16 17H8M10 9H8"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CardIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M0 0h24v30H0z" />
        <path fill="currentColor" d="M-983-622H617v3436H-983z" />
        <path
            d="M-456.5-86a4.5 4.5 0 0 1 4.5-4.5h860a4.5 4.5 0 0 1 4.5 4.5v304a4.5 4.5 0 0 1-4.5 4.5h-860a4.5 4.5 0 0 1-4.5-4.5V-86Z"
            fill="#f6f6f7"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path
            d="M14 2.498H6c-.53 0-1.04.263-1.414.732C4.21 3.698 4 4.334 4 4.996v19.985c0 .662.21 1.298.586 1.766.375.469.884.732 1.414.732h12c.53 0 1.04-.263 1.414-.732.375-.468.586-1.104.586-1.766V9.992l-6-7.494Z"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path d="M14 2.498v7.494h6M16 16.237H8M16 21.234H8M10 11.241H8" stroke="currentColor" strokeWidth="2" />
    </svg>
);

export const UnamusedFaceEmojiIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M49.99 6.301A43.71 43.71 0 1 1 6.3 50.011 43.772 43.772 0 0 1 49.99 6.3Zm0-6.301A50.01 50.01 0 1 0 100 50.01 49.985 49.985 0 0 0 69.131 3.801 49.99 49.99 0 0 0 49.989 0Z"
                fill="currentColor"
            />
            <path d="m25.604 70.132 48.792-4.473" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" />
            <path
                d="M65.238 43.29a6.091 6.091 0 1 0 0-12.183 6.091 6.091 0 0 0 0 12.182ZM34.762 43.29a6.091 6.091 0 1 0 0-12.183 6.091 6.091 0 0 0 0 12.182Z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const GoodsReturningIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33198 46.578L9.33203 46.5795L9.33208 46.581C9.33208 46.5812 9.33204 46.5799 9.33197 46.5771L9.33191 46.5759L9.33121 46.5431C9.33056 46.5089 9.32974 46.452 9.3295 46.3739C9.32901 46.2176 9.33085 45.9769 9.34095 45.6641C9.36118 45.0376 9.41438 44.1269 9.54706 43.0279C9.81417 40.8157 10.3946 37.9157 11.6261 35.0541C12.8547 32.199 14.6951 29.4633 17.4457 27.4395C20.1692 25.4356 23.9593 24 29.3334 24C34.7068 24 38.564 25.4357 41.3814 27.458C44.2255 29.4994 46.1718 32.2565 47.5031 35.1231C48.837 37.9955 49.5173 40.9028 49.8599 43.1162C50.0302 44.2163 50.1145 45.1278 50.1561 45.7546C50.1769 46.0676 50.1869 46.3083 50.1917 46.4646C50.1941 46.5428 50.1953 46.5997 50.1958 46.6339L50.1962 46.6679L50.1962 46.6688C50.1962 46.6689 50.1962 46.669 50.1962 46.6691C50.1962 46.6697 50.1962 46.6703 50.1962 46.671C50.1986 48.1417 51.3916 49.3333 52.8629 49.3333C54.3356 49.3333 55.5295 48.1394 55.5295 46.6666H52.8629C55.5295 46.6666 55.5295 46.6653 55.5295 46.6639L55.5295 46.6606L55.5295 46.6523L55.5294 46.6286L55.5285 46.554C55.5276 46.4923 55.5258 46.407 55.5225 46.2996C55.5159 46.0848 55.5029 45.7813 55.4777 45.4016C55.4274 44.643 55.3279 43.5753 55.1305 42.3003C54.7378 39.7638 53.9475 36.3377 52.3403 32.8768C50.7304 29.4101 48.2649 25.8338 44.4914 23.1253C40.6911 20.3975 35.7247 18.6666 29.3334 18.6666C22.9428 18.6666 18.0172 20.3976 14.2849 23.1437C10.5796 25.8699 8.22393 29.4676 6.72709 32.9458C5.23308 36.4176 4.5586 39.8508 4.25219 42.3886C4.09811 43.6647 4.03489 44.7332 4.01039 45.492C3.99813 45.8719 3.99552 46.1756 3.99619 46.3904C3.99652 46.4978 3.99767 46.5831 3.99885 46.6448L4.00053 46.7194L4.00121 46.743L4.00147 46.7514L4.00158 46.7547C4.00162 46.7561 4.00167 46.7574 6.66679 46.6666L4.00167 46.7574C4.0518 48.2293 5.28565 49.3819 6.75756 49.3317C8.22875 49.2816 9.38093 48.0489 9.33198 46.578Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.9995 26.7509C59.4256 27.1187 60.2835 28.573 59.9157 29.9991L55.4451 47.3326C55.2227 48.1947 54.5848 48.8889 53.7446 49.1833C52.9044 49.4777 51.9727 49.3333 51.261 48.7985L37.0649 38.1317C35.8875 37.2469 35.6502 35.5753 36.5349 34.3978C37.4196 33.2204 39.0913 32.9831 40.2687 33.8678L51.2767 42.1392L54.7513 28.6671C55.1191 27.241 56.5734 26.3831 57.9995 26.7509Z"
            fill="currentColor"
        />
    </svg>
);

export const LaboratoryTestIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
        <g fill="#000">
            <path d="M55.8 31.1a2 2 0 0 0-.4-3.2l-2.1-1.4a4 4 0 0 1-1.4-3.8l.6-2.4c.3-1.3-.5-2.5-1.9-2.7l-2.5-.3a4 4 0 0 1-3-2.7l-.6-2.4c-.3-1.3-1.6-2-2.9-1.5l-2.4.9c-1.3.5-3 0-3.9-1l-1.7-1.9c-.9-1-2.4-1-3.3 0l-1.7 1.9a4 4 0 0 1-3.9 1l-2.4-.9c-1.3-.5-2.6.2-2.9 1.5l-.6 2.4a4 4 0 0 1-3 2.7l-2.5.3a2.2 2.2 0 0 0-1.9 2.7l.6 2.4a4 4 0 0 1-1.4 3.8l-2.1 1.4c-1.1.8-1.3 2.2-.4 3.2L9.7 33c.9 1 1.1 2.8.5 4l-1.1 2.2c-.6 1.2 0 2.6 1.2 3.1l2.3.9c1.2.5 2.3 2 2.3 3.3V49c0 1.3 1.1 2.3 2.5 2.2l2.5-.3c1.3-.1 2.9.7 3.6 1.9l1.2 2.2c.6 1.2 2.1 1.5 3.2.8l2.1-1.4a4 4 0 0 1 4 0l2.1 1.4c1.1.7 2.5.4 3.2-.8l1.2-2.2c.6-1.2 2.2-2 3.6-1.9l2.5.3c1.3.1 2.4-.8 2.4-2.2v-2.5c0-1.3 1.1-2.8 2.3-3.3l2.3-.9c1.2-.5 1.8-1.9 1.2-3.1L53.7 37c-.6-1.2-.4-3 .5-4l1.6-1.9ZM32 48.7a16.7 16.7 0 0 1 0-33.4 16.7 16.7 0 0 1 0 33.4Z" />
            <path d="M43 22.8c-1-.8-2-2.2-3.1-.7-1 1.5-10.5 12.2-10.5 12.2l-5.3-4.7s-1.5-.9-2.7.3 0 3.1 0 3.1l7.5 9s1 1.2 2-.4S42.7 25 42.7 25s1.1-1.4 0-2.2Z" />
        </g>
        <path d="M-232.3 0h64v64h-64z" />
    </svg>
);

export const BrandedEshopIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.1582 2.96532C31.2415 2.12273 32.7585 2.12273 33.8418 2.96532L57.8418 21.632C58.5726 22.2004 59 23.0743 59 24V53.3334C59 55.5435 58.122 57.6631 56.5592 59.2259C54.9964 60.7887 52.8768 61.6667 50.6667 61.6667H13.3333C11.1232 61.6667 9.00358 60.7887 7.44078 59.2259C5.87797 57.6631 5 55.5435 5 53.3334V24C5 23.0743 5.42742 22.2004 6.15818 21.632L30.1582 2.96532ZM11 25.4673V53.3334C11 53.9522 11.2458 54.5457 11.6834 54.9833C12.121 55.4209 12.7145 55.6667 13.3333 55.6667H50.6667C51.2855 55.6667 51.879 55.4209 52.3166 54.9833C52.7542 54.5457 53 53.9522 53 53.3334V25.4673L32 9.13396L11 25.4673Z"
            fill="currentColor"
        />
    </svg>
);

export const SpinnerIcon: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        viewBox="0 0 32 32"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity=".25" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4" />
        <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 16 16"
                to="360 16 16"
                dur="0.8s"
                repeatCount="indefinite"
            />
        </path>
    </svg>
);

export const PlayIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.629883 7.64584L6.70503 4.23959L0.629883 0.833344V7.64584Z" fill="currentColor" />
    </svg>
);

export const SupportChatIcon: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="70"
        viewBox="0 0 50 50"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="25" cy="25" r="25" fill="#d90000" />
            <path
                strokeWidth="2"
                d="M36.8 24.7c0 5.4-5.1 9.7-11.4 9.7-1.5 0-3-.3-4.3-.7-1.8 1-3.4 1.6-5.6 2-.7.2-1 .2-1 0l.4-.3c2-1.6 2.4-2.5 3-3.4a9 9 0 0 1-4-7.3c0-5.3 5.1-9.6 11.5-9.6 6.3 0 11.4 4.3 11.4 9.6z"
                stroke="#fff"
            />
        </g>
    </svg>
);

export const RotateIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="currentColor"
            d="M17.569 10.205a.75.75 0 0 0 1.442-.413l-1.442.413Zm-.979-3.156.54-.52v-.001l-.54.52Zm-3.479-1.915.151-.735-.151.735Zm-3.921.4-.295-.69h-.001l.296.69ZM6.144 8.11l-.63-.407.63.407Zm.844 8.839.542-.518-.542.518Zm8.569.87.427.617-.427-.616Zm3.197-2.864a.75.75 0 0 0-1.394-.555l1.394.555Zm-1.19-5.145a.75.75 0 1 0 1.452.377l-1.452-.377Zm2.162-2.355a.75.75 0 1 0-1.452-.377l1.452.377Zm-1.636 3.267a.75.75 0 0 0 .4-1.446l-.4 1.446Zm-2.25-2.178a.75.75 0 0 0-.399 1.446l.4-1.446Zm3.171 1.248a7.798 7.798 0 0 0-1.88-3.264L16.05 7.57a6.297 6.297 0 0 1 1.519 2.636l1.442-.413ZM17.13 6.528a7.475 7.475 0 0 0-3.867-2.129l-.304 1.47a5.975 5.975 0 0 1 3.091 1.7l1.08-1.041Zm-3.869-2.13a7.35 7.35 0 0 0-4.366.446l.59 1.379a5.85 5.85 0 0 1 3.476-.355l.3-1.47Zm-4.367.447a7.587 7.587 0 0 0-3.38 2.858l1.26.813a6.087 6.087 0 0 1 2.712-2.293l-.592-1.378Zm-3.38 2.858a7.918 7.918 0 0 0 .932 9.764L7.53 16.43a6.418 6.418 0 0 1-.756-7.914l-1.26-.813Zm.932 9.764a7.38 7.38 0 0 0 9.538.97l-.854-1.234a5.88 5.88 0 0 1-7.6-.772l-1.084 1.036Zm9.538.97a7.708 7.708 0 0 0 2.77-3.482L17.36 14.4a6.208 6.208 0 0 1-2.23 2.803l.854 1.233Zm3.032-8.25.71-2.732-1.452-.377-.71 2.732 1.452.377Zm-.526-.911-2.65-.732-.399 1.446 2.65.732.399-1.446Z"
        />
    </svg>
);

export const ReviewStarFull: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M12 16.7 5 22l3-8-6-4.5h7.5L12 2l2.5 7.5H22L16 14l3 8z" />
    </svg>
);

export const ReviewStarEmpty: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fillOpacity=".2" d="M12 16.7 5 22l3-8-6-4.5h7.5L12 2l2.5 7.5H22L16 14l3 8z" />
    </svg>
);

export const ReviewStarHalfFull: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g fill="#000" fillRule="evenodd">
            <path fillOpacity=".2" d="M12 16.7V2l2.5 7.5H22L16 14l3 8z" />
            <path d="M12 16.7 5 22l3-8-6-4.5h7.5L12 2z" />
        </g>
    </svg>
);

export const AppleWallet: FC = ({ className }) => (
    <svg
        className={twMerge('icon', className)}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 198.437 149.001"
    >
        <defs>
            <linearGradient id="a">
                <stop
                    offset={0}
                    style={{
                        stopColor: '#cbc9be',
                        stopOpacity: 1,
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: '#c8c5bb',
                        stopOpacity: 0,
                    }}
                />
            </linearGradient>
            <linearGradient
                xlinkHref="#a"
                id="f"
                x1={-53.583}
                x2={-53.583}
                y1={120.722}
                y2={114.418}
                gradientUnits="userSpaceOnUse"
            />
            <linearGradient
                xlinkHref="#a"
                id="g"
                x1={-53.583}
                x2={-53.583}
                y1={120.722}
                y2={114.418}
                gradientTransform="translate(190.175)"
                gradientUnits="userSpaceOnUse"
            />
            <clipPath id="b" clipPathUnits="userSpaceOnUse">
                <path
                    d="M-31.595 54.697c-10.576 0-13.726 6.339-13.726 14.212v16.6c0-7.873 3.15-14.211 13.726-14.211h154.462c10.576 0 13.726 6.338 13.726 14.211v-16.6c0-7.873-3.15-14.212-13.726-14.212z"
                    style={{
                        opacity: 1,
                        fill: '#4095ca',
                        fillOpacity: 1,
                        stroke: 'none',
                        strokeWidth: 18,
                        strokeLinecap: 'square',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none',
                        strokeOpacity: 0.607477,
                    }}
                />
            </clipPath>
            <clipPath id="d" clipPathUnits="userSpaceOnUse">
                <path
                    d="M83.818 394.032c-39.972 0-51.877 23.956-51.877 53.713v7.607l176.409.326c47.129.087 66.427 19.048 86.066 45.578 5.002 6.757 11.086 15.914 17.027 21.861 17.742 17.758 41.006 26.63 64.272 26.63 23.266 0 46.53-8.872 64.271-26.63 5.942-5.947 12.026-15.104 17.028-21.861 19.639-26.53 38.937-45.491 86.066-45.578l176.408-.326v-7.607c0-29.757-11.904-53.713-51.877-53.713z"
                    style={{
                        opacity: 1,
                        fill: '#f16d5e',
                        fillOpacity: 1,
                        stroke: 'none',
                        strokeWidth: 18,
                        strokeLinecap: 'square',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none',
                        strokeOpacity: 0.607477,
                    }}
                />
            </clipPath>
            <filter id="c" width={1.094} height={1.555} x={-0.047} y={-0.278} colorInterpolationFilters="sRGB">
                <feGaussianBlur stdDeviation={3.563} />
            </filter>
            <filter id="e" width={1.107} height={1.143} x={-0.054} y={-0.071} colorInterpolationFilters="sRGB">
                <feGaussianBlur stdDeviation={16.743} />
            </filter>
        </defs>
        <g
            style={{
                fill: 'red',
                fillOpacity: 0,
                strokeWidth: 0.997187,
            }}
        >
            <path
                fill="none"
                d="M10.108.243c-36.91 0-63.88 22.395-63.88 64.796v133.506c0 42.401 26.97 64.795 63.88 64.795h136.823c36.911 0 63.88-22.394 63.88-64.795V65.039c0-42.401-26.969-64.796-63.88-64.796z"
                style={{
                    opacity: 1,
                    fill: 'red',
                    fillOpacity: 0,
                    stroke: 'none',
                    strokeWidth: 2.09409,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 4,
                    strokeDasharray: 'none',
                    strokeOpacity: 0.383178,
                }}
                transform="matrix(1 0 0 1.00565 78.151 -41.405)"
            />
        </g>
        <path
            d="M77.738 174.234c-50.5 0-77.023 30.817-77.023 77.024v409.105c0 46.207 26.523 77.024 77.023 77.024h595.953c50.5 0 77.024-30.817 77.024-77.024V251.258c0-46.207-26.524-77.024-77.024-77.024z"
            style={{
                opacity: 1,
                fill: '#d9d6cc',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 155.717,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 1,
            }}
            transform="translate(-.19 -46.1) scale(.26458)"
        />
        <path
            d="M-31.595 54.697c-10.576 0-13.726 6.339-13.726 14.212v51.813l46.675.086c12.47.023 17.576 5.04 22.772 12.06 1.323 1.787 2.933 4.21 4.505 5.783a23.96 23.96 0 0 0 17.005 7.046 23.96 23.96 0 0 0 17.005-7.046c1.572-1.573 3.182-3.996 4.505-5.784 5.196-7.02 10.302-12.036 22.772-12.059l46.675-.086V68.909c0-7.873-3.15-14.212-13.726-14.212z"
            style={{
                opacity: 1,
                fill: '#f16d5e',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 18,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
            }}
            transform="translate(53.583 -46.343)"
        />
        <path
            d="M83.818 205.81c-39.972 0-51.877 23.956-51.877 53.713v62.741c0-29.757 11.905-53.713 51.877-53.713h583.793c39.973 0 51.877 23.956 51.877 53.713v-62.74c0-29.758-11.904-53.713-51.877-53.713z"
            style={{
                opacity: 1,
                fill: '#3295c9',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 68.0315,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
            }}
            transform="translate(-.19 -46.1) scale(.26458)"
        />
        <path
            d="M-31.595 71.298c-10.576 0-13.726 6.338-13.726 14.211v16.6c0-7.873 3.15-14.211 13.726-14.211h154.462c10.576 0 13.726 6.338 13.726 14.211v-16.6c0-7.873-3.15-14.211-13.726-14.211z"
            clipPath="url(#b)"
            style={{
                opacity: 1,
                fill: '#000',
                fillOpacity: 0.317757,
                stroke: 'none',
                strokeWidth: 18,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
                filter: 'url(#c)',
            }}
            transform="translate(53.583 -46.343)"
        />
        <path
            d="M-31.595 71.298c-10.576 0-13.726 6.338-13.726 14.211v16.6c0-7.873 3.15-14.211 13.726-14.211h154.462c10.576 0 13.726 6.338 13.726 14.211v-16.6c0-7.873-3.15-14.211-13.726-14.211z"
            style={{
                opacity: 1,
                fill: '#fcad00',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 18,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
            }}
            transform="translate(53.583 -46.343)"
        />
        <path
            d="M-31.595 71.298c-10.576 0-13.726 6.338-13.726 14.211v16.6c0-7.873 3.15-14.211 13.726-14.211h154.462c10.576 0 13.726 6.338 13.726 14.211v-16.6c0-7.873-3.15-14.211-13.726-14.211z"
            clipPath="url(#b)"
            style={{
                opacity: 1,
                fill: '#000',
                fillOpacity: 0.313725,
                stroke: 'none',
                strokeWidth: 18,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
                filter: 'url(#c)',
            }}
            transform="translate(53.583 -29.743)"
        />
        <path
            d="M-31.595 87.898c-10.576 0-13.726 6.338-13.726 14.211v16.6c0-7.873 3.15-14.211 13.726-14.211h154.462c10.576 0 13.726 6.338 13.726 14.211v-16.6c0-7.873-3.15-14.211-13.726-14.211z"
            style={{
                opacity: 1,
                fill: '#50be3d',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 18,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
            }}
            transform="translate(53.583 -46.343)"
        />
        <path
            d="M-31.595 71.298c-10.576 0-13.726 6.338-13.726 14.211v16.6c0-7.873 3.15-14.211 13.726-14.211h154.462c10.576 0 13.726 6.338 13.726 14.211v-16.6c0-7.873-3.15-14.211-13.726-14.211z"
            clipPath="url(#b)"
            style={{
                opacity: 1,
                fill: '#000',
                fillOpacity: 0.313725,
                stroke: 'none',
                strokeWidth: 18,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
                filter: 'url(#c)',
            }}
            transform="translate(53.583 -13.143)"
        />
        <path
            d="M77.738 174.234c-50.5 0-77.023 30.817-77.023 77.024v409.105c0 46.207 26.523 77.024 77.023 77.024h595.953c50.5 0 77.024-30.817 77.024-77.024V251.258c0-46.207-26.524-77.024-77.024-77.024zm6.08 31.577h583.793c39.973 0 51.877 23.955 51.877 53.712v195.829l-176.408.326c-47.13.087-66.427 19.048-86.066 45.578-5.002 6.756-11.086 15.914-17.028 21.861-17.741 17.758-41.005 26.63-64.271 26.63-23.266-.001-46.53-8.872-64.272-26.63-5.941-5.947-12.025-15.105-17.027-21.861-19.639-26.53-38.937-45.491-86.066-45.578l-176.409-.326V259.523c0-29.757 11.905-53.712 51.877-53.712z"
            clipPath="url(#d)"
            style={{
                opacity: 1,
                fill: '#f26d5f',
                fillOpacity: 0.29803923,
                stroke: 'none',
                strokeWidth: 155.717,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 1,
                filter: 'url(#e)',
            }}
            transform="translate(-.19 -46.1) scale(.26458)"
        />
        <path
            d="M-53.583 108.559h8.262v12.163h-8.262z"
            style={{
                opacity: 1,
                fill: 'url(#f)',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 18,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
            }}
            transform="translate(53.583 -46.343)"
        />
        <path
            d="M136.593 108.559h8.262v12.163h-8.262z"
            style={{
                opacity: 1,
                fill: 'url(#g)',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 18,
                strokeLinecap: 'square',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 0.607477,
            }}
            transform="translate(53.583 -46.343)"
        />
    </svg>
);

export const GoogleWallet: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 29 26">
        <path fill="#34A853" d="M29 9.63H0V4.815C0 2.167 2.105 0 4.677 0h19.646C26.895 0 29 2.167 29 4.815V9.63Z" />
        <path
            fill="#FB0"
            d="M29 13.722H0V8.907c0-2.648 2.105-4.814 4.677-4.814h19.646C26.895 4.093 29 6.259 29 8.907v4.815Z"
        />
        <path
            fill="#FE2C25"
            d="M29 17.815H0V13c0-2.648 2.105-4.815 4.677-4.815h19.646C26.895 8.185 29 10.352 29 13v4.815Z"
        />
        <path
            fill="#4285F4"
            fillRule="evenodd"
            d="m0 10.785 18.406 4.526c2.175.53 4.49.048 6.267-1.348L29 10.593v10.592C29 23.833 26.895 26 24.323 26H4.677C2.105 26 0 23.833 0 21.185v-10.4Z"
        />
    </svg>
);

export const GoogleIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
            fill="#FFC107"
        />
        <path
            d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
            fill="#FF3D00"
        />
        <path
            d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
            fill="#4CAF50"
        />
        <path
            d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
            fill="#1976D2"
        />
    </svg>
);

export const SeznamIcon: FC = ({ className }) => (
    <svg className={twMerge('icon', className)} viewBox="0 0 25 26" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.68 1.69c-.73.12-1.45.25-2.17.4L9.1 2.4c-.43.11-.87.21-1.3.34-.4.13-.8.26-1.21.42A7.78 7.78 0 0 0 3.35 5.5c-.22.3-.4.6-.57.92-.17.3-.28.67-.28 1.02 0 1.03.65 1.89 1.4 2.53A18.7 18.7 0 0 0 7.24 12c.91.43 1.83.84 2.76 1.23.87.37 1.77.69 2.67.98l.78.26c.82.27 1.66.52 2.47.81.5.18 1.05.35 1.55.58.53.24 1.07.47 1.54.81.3.22.58.5.77.83.2.32.32.68.33 1.06.01.36-.1.7-.29 1.01a4.8 4.8 0 0 1-2.25 1.69c-1.27.53-2.57 1-3.9 1.38a77 77 0 0 1-2.28.64l-2.32.6c-.77.19-1.54.36-2.31.53l-1.34.27-.79.17-1.66.36-1.9.4-.55.14c-.16.03-.78.17-.32.15.63-.04 2.32-.18 5.93-.66 1.31-.22 2.62-.4 3.93-.61a70.3 70.3 0 0 0 6.24-1.3l.9-.27a62.6 62.6 0 0 0 3.78-1.28c1.83-.8 2.98-1.4 3.57-2.83a3.39 3.39 0 0 0-.25-3.06c-.25-.41-.6-.76-.96-1.1a10.36 10.36 0 0 0-2.91-1.83 29.66 29.66 0 0 0-2.14-.85c-1.1-.37-2.22-.7-3.32-1.06a23.49 23.49 0 0 1-2.62-1.02c-.29-.14-.57-.3-.86-.46-.34-.21-.91-.57-.75-1.06.1-.34.49-.48.8-.6.29-.12.6-.2.9-.28.9-.23 1.84-.39 2.77-.58l.8-.18c.73-.17 1.47-.3 2.16-.56.59-.23 1.35-.6 1.57-1.24.31-.94-.05-1.95-.56-2.74l-.58-.84c-.27-.37-.57-.73-.86-1.09-.14-.16-.3-.15-.43-.1-.18.06-.68.63-4.62 1.38"
            fill="#c00"
        />
    </svg>
);

export const MessageInfoIcon: FC = ({ className }) => (
    <svg className={className} width="12" height="12" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.859 1.564C1.859 1.168 1.98733 0.874666 2.244 0.684C2.50067 0.493333 2.81967 0.397999 3.201 0.397999C3.52367 0.397999 3.76567 0.460333 3.927 0.585C4.09567 0.709666 4.18 0.896666 4.18 1.146C4.18 1.49067 4.048 1.76933 3.784 1.982C3.52733 2.18733 3.20833 2.29 2.827 2.29C2.51167 2.29 2.26967 2.22767 2.101 2.103C1.93967 1.97833 1.859 1.79867 1.859 1.564ZM1.397 4.226C1.49967 3.918 1.46667 3.764 1.298 3.764C1.188 3.764 1.08533 3.81533 0.99 3.918C0.894667 4.01333 0.795667 4.193 0.693 4.457L0.44 5.128H0.231L0.539 4.27C0.641667 3.99133 0.77 3.77133 0.924 3.61C1.08533 3.44133 1.27233 3.32033 1.485 3.247C1.69767 3.17367 1.92867 3.137 2.178 3.137C2.45667 3.137 2.68033 3.18467 2.849 3.28C3.01767 3.37533 3.13867 3.5 3.212 3.654C3.28533 3.80067 3.322 3.96567 3.322 4.149C3.322 4.325 3.29267 4.501 3.234 4.677L2.09 8.065C2.03133 8.24833 2.02033 8.373 2.057 8.439C2.09367 8.49767 2.14867 8.527 2.222 8.527C2.288 8.527 2.37233 8.49033 2.475 8.417C2.57767 8.33633 2.68767 8.142 2.805 7.834L3.036 7.218H3.245L2.97 8.021C2.86 8.329 2.72067 8.56367 2.552 8.725C2.38333 8.88633 2.19633 8.99633 1.991 9.055C1.793 9.121 1.595 9.154 1.397 9.154C1.07433 9.154 0.810333 9.088 0.605 8.956C0.407 8.81667 0.286 8.60767 0.242 8.329C0.198 8.043 0.245667 7.68733 0.385 7.262L1.397 4.226Z"
            fill="white"
        />
    </svg>
);

export const CopyIcon: FC = ({className}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 26.2">
        <rect width="12.7" height="17" x="7.5" y="1.8" style={{
            fill: 'none',
            stroke: '#fff',
            strokeWidth: '2px'
        }} rx="2.7" ry="2.7"/>
        <path d="M5.6 7.6C4.5 8 3.8 9 3.8 10.2v11.6c0 1.5 1.2 2.7 2.7 2.7h7.2c1.5 0 2.7-1.2 2.7-2.7v-1" style={{
            fill: 'none',
            stroke: '#fff',
            strokeWidth: '2px'
        }}/>
    </svg>
);

export const MenuHeartIcon: FC = ({className}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
            d="M12 21.8s4.1-1.4 8.1-6.6c3.6-4.8 2.4-10.1-1.6-10.9-4.6-.9-6.5 3.2-6.5 3.2s-1.9-4.1-6.5-3.2c-3.9.8-5.2 6.1-1.6 10.9 3.9 5.2 8.1 6.6 8.1 6.6Z"
            style={{
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "1.5px",
                fill: "none",
            }}/>
    </svg>
)

export const MenuUserIcon: FC = ({className}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M7.1 8.7V6.5c0-2.7 2.2-4.9 4.9-4.9s4.9 2.2 4.9 4.9v2.2c0 2.7-2.2 4.9-4.9 4.9s-4.9-2.2-4.9-4.9Z"
              style={{
                  strokeLinecap: "round",
                  strokeWidth: "1.5px",
                  strokeMiterlimit: 10,
                  fill: "none",
              }}/>
        <path
            d="M21.8 17c-2.1 3.2-5.7 5.3-9.8 5.3S4.3 20.2 2.2 17c.9-1 2.1-1.7 3.5-2l6.3-1.4 6.3 1.4c1.4.3 2.6 1 3.5 2Z"
            style={{
                strokeLinejoin: "round",
                strokeLinecap: "round",
                strokeWidth: "1.5px",
                fill: "none",
            }}/>
    </svg>
)

export const MenuBasketIcon: FC = ({className}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
        <path d="m2.6 10.8.6 8.4c0 .9.8 1.6 1.7 1.6h14.2c.9 0 1.7-.7 1.7-1.6l.6-8.4" style={{
            strokeMiterlimit: 10,
            strokeWidth: "1.5px",
            fill: "none",
        }}/>
        <path d="m16.5 18.5.4-5.4M7.5 18.5l-.4-5.4M18.9 10.8h2.6c.5 0 .8-.3.9-.8v-.9c.1-.5-.3-1-.8-1h-10M12 13.1v5.4"
              style={{
                  strokeWidth: "1.5px",
                  strokeMiterlimit: 10,
                  fill: "none",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
              }}/>
        <path
            d="M9.7 8.2H2.5c-.5 0-.9.4-.9 1v.9c.1.4.5.8.9.8H12c.8 0 1.2-.9.6-1.5l-7.8-8c-.2-.2-.6-.2-.9 0h0c-.2.2-.2.6 0 .9l8.4 8.4"
            style={{
                strokeMiterlimit: 10,
                strokeWidth: "1.5px",
                strokeLinecap: "round",
                fill: "none",
            }}/>
    </svg>
)

export const AddToCartBasketSmallIcon: FC = ({className}) => (
    <svg className={twMerge('stroke-white', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="m1.7 7.2.4 5.6c0 .6.5 1.1 1.2 1.1h9.5c.6 0 1.1-.5 1.2-1.1l.4-5.6" className="stroke-current"
              strokeWidth="1.5" strokeMiterlimit="10" fill="none"/>
        <path d="m10.1 11.7.2-2.6M5.9 11.7l-.3-2.6" fill="none" className="stroke-current" strokeWidth="1.5"
              strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.4 6.2 3.2.9C3 .7 2.8.7 2.6.9h0c-.2.2-.2.4 0 .6L6 4.9" fill="none" className="stroke-current"
              strokeWidth="1.5" strokeLinecap="round" strokeMiterlimit="10"/>
        <path d="M2.8 1.7 8.1 7c.2.2.4.2.6 0h0c.2-.2.2-.4 0-.6L3.1.8" fill="none" className="stroke-current"
              strokeWidth="1.5" strokeLinecap="round" strokeMiterlimit="10"/>
        <path d="M12.6 7.2h1.7c.3 0 .6-.2.6-.5v-.6c0-.3-.2-.6-.5-.6H7.7" fill="none" className="stroke-current"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 5.4H1.6c-.3 0-.6.3-.6.6v.6c0 .3.3.5.6.5h1.6" fill="none" className="stroke-current"
              strokeWidth="1.5" strokeLinecap="round" strokeMiterlimit="10"/>
    </svg>
);
