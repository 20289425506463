import getConfig from 'next/config';

const nextConfig = getConfig();

export const STATIC_REWRITE_PATHS = {
    [(nextConfig?.publicRuntimeConfig?.domains?.[0]?.url ||
        process.env.DOMAIN_HOSTNAME_1 ||
        'smokeTestDomain') as string]: {
        '/search': '/hledani',
        '/cart': '/kosik',
        '/order/transport-and-payment': '/objednavka/doprava-a-platba',
        '/order/contact-information': '/objednavka/kontaktni-udaje',
        '/reset-password': '/zapomenute-heslo',
        '/order-confirmation': '/potvrzeni-objednavky',
        '/stores': '/prodejny',
        '/contact': '/kontakty',
        '/product-lines': '/produktove-linie',
        '/catalog': '/katalog',
        '/login': '/prihlaseni',
        '/customer': '/zakaznik',
        '/customer/edit-profile': '/zakaznik/upravit-udaje',
        '/customer/orders': '/zakaznik/objednavky',
        '/customer/order-detail': '/zakaznik/detail-objednavky',
        '/customer/exchange-office': '/zakaznik/smenarna',
        '/customer/my-points': '/zakaznik/moje-body',
        '/customer/watched-items': '/zakaznik/hlidane-polozky',
        '/registration': '/registrace',
        '/terms-and-conditions': '/obchodni-podminky',
        '/gdpr': '/zasady-ochrany-osobnich-udaju',
        '/recover-password': '/nove-heslo',
        '/personal-data-overview': '/prehled-osobnich-udaju',
        '/personal-data-overview/:hash': '/prehled-osobnich-udaju/:hash',
        '/personal-data-export': '/export-osobnich-udaju',
        '/order-payment-confirmation': '/potvrzeni-platby-objednavky',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/detail-objednavky/:urlHash',
        '/cookie-consent': '/souhlas-se-soubory-cookies',
        '/abandoned-cart/:cartUuid': '/kosik/:cartUuid',
        '/tescoma-club': '/tescoma-klub',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/platebni-brana',
        '/maintenance': '/udrzba',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/oblibene-produkty',
    },
    [(nextConfig?.publicRuntimeConfig?.domains?.[1]?.url || process.env.DOMAIN_HOSTNAME_2) as string]: {
        '/search': '/hledani',
        '/cart': '/kosik',
        '/order/transport-and-payment': '/objednavka/doprava-a-platba',
        '/order/contact-information': '/objednavka/kontaktne-udaje',
        '/b2b-reset-password': '/zapomenute-heslo',
        '/order-confirmation': '/potvrzeni-objednavky',
        '/stores': '/prodejny',
        '/contact': '/kontakty',
        '/product-lines': '/produktove-linie',
        '/catalog': '/katalog',
        '/b2b-login': '/prihlaseni',
        '/customer': '/zakaznik',
        '/customer/edit-profile': '/zakaznik/upravit-udaje',
        '/customer/orders': '/zakaznik/objednavky',
        '/customer/order-detail': '/zakaznik/detail-objednavky',
        '/customer/exchange-office': '/zakaznik/smenarna',
        '/customer/my-points': '/zakaznik/moje-body',
        '/customer/watched-items': '/zakaznik/hlidane-polozky',
        '/registration': '/registrace',
        '/terms-and-conditions': '/obchodni-podminky',
        '/gdpr': '/zasady-ochrany-osobnich-udaju',
        '/b2b-recover-password': '/nove-heslo',
        '/personal-data-overview': '/prehled-osobnich-udaju',
        '/personal-data-overview/:hash': '/prehled-osobnich-udaju/:hash',
        '/personal-data-export': '/export-osobnich-udaju',
        '/order-payment-confirmation': '/potvrzeni-platby-objednavky',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/detail-objednavky/:urlHash',
        '/cookie-consent': '/souhlas-se-soubory-cookies',
        '/abandoned-cart/:cartUuid': '/kosik/:cartUuid',
        '/tescoma-club': '/tescoma-klub',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/platebni-brana',
        '/maintenance': '/udrzba',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/oblibene-produkty',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[2]?.url || process.env.DOMAIN_HOSTNAME_3]: {
        '/search': '/hladanie',
        '/cart': '/kosik',
        '/order/transport-and-payment': '/objednavka/doprava-a-platba',
        '/order/contact-information': '/objednavka/kontaktne-udaje',
        '/reset-password': '/zapomenute-heslo',
        '/order-confirmation': '/potvrdenie-objednavky',
        '/stores': '/predajne',
        '/contact': '/kontakty',
        '/product-lines': '/produktove-linie',
        '/catalog': '/katalog',
        '/login': '/prihlasenie',
        '/customer': '/zakaznik',
        '/customer/edit-profile': '/zakaznik/upravit-udaje',
        '/customer/orders': '/zakaznik/objednavky',
        '/customer/order-detail': '/zakaznik/detail-objednavky',
        '/customer/exchange-office': '/zakaznik/smenarna',
        '/customer/my-points': '/zakaznik/moje-body',
        '/customer/watched-items': '/zakaznik/hlidane-polozky',
        '/registration': '/registracia',
        '/terms-and-conditions': '/podmienky-a-ustanovenia',
        '/gdpr': '/zasady-ochrany-osobnych-udajov',
        '/recover-password': '/nove-heslo',
        '/personal-data-overview': '/prehlad-osobnych-udajov',
        '/personal-data-overview/:hash': '/prehlad-osobnych-udajov/:hash',
        '/personal-data-export': '/export-osobnych-udajov',
        '/order-payment-confirmation': '/potvrdenie-platby-objednavky',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/detail-objednavky/:urlHash',
        '/cookie-consent': '/suhlas-so-subormi-cookies',
        '/abandoned-cart/:cartUuid': '/kosik/:cartUuid',
        '/tescoma-club': '/tescoma-klub',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/platobna-brana',
        '/maintenance': '/udrzba',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/oblubene-produkty',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[3]?.url || process.env.DOMAIN_HOSTNAME_4]: {
        '/search': '/hladanie',
        '/cart': '/kosik',
        '/order/transport-and-payment': '/objednavka/doprava-a-platba',
        '/order/contact-information': '/objednavka/kontaktne-udaje',
        '/b2b-reset-password': '/zapomenute-heslo',
        '/order-confirmation': '/potvrdenie-objednavky',
        '/stores': '/predajne',
        '/contact': '/kontakty',
        '/product-lines': '/produktove-linie',
        '/catalog': '/katalog',
        '/b2b-login': '/prihlasenie',
        '/customer': '/zakaznik',
        '/customer/edit-profile': '/zakaznik/upravit-udaje',
        '/customer/orders': '/zakaznik/objednavky',
        '/customer/order-detail': '/zakaznik/detail-objednavky',
        '/customer/exchange-office': '/zakaznik/smenarna',
        '/customer/my-points': '/zakaznik/moje-body',
        '/customer/watched-items': '/zakaznik/hlidane-polozky',
        '/registration': '/registracia',
        '/terms-and-conditions': '/podmienky-a-ustanovenia',
        '/gdpr': '/zasady-ochrany-osobnych-udajov',
        '/b2b-recover-password': '/nove-heslo',
        '/personal-data-overview': '/prehlad-osobnych-udajov',
        '/personal-data-overview/:hash': '/prehlad-osobnych-udajov/:hash',
        '/personal-data-export': '/export-osobnych-udajov',
        '/order-payment-confirmation': '/potvrdenie-platby-objednavky',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/detail-objednavky/:urlHash',
        '/cookie-consent': '/suhlas-so-subormi-cookies',
        '/abandoned-cart/:cartUuid': '/kosik/:cartUuid',
        '/tescoma-club': '/tescoma-klub',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/platobna-brana',
        '/maintenance': '/udrzba',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/oblubene-produkty',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[4]?.url || process.env.DOMAIN_HOSTNAME_5]: {
        '/search': '/wyszukiwanie',
        '/cart': '/koszyk',
        '/order/transport-and-payment': '/zamowienie/dostawa-i-platnosc',
        '/order/contact-information': '/zamowienie/dane-kontaktowe',
        '/reset-password': '/resetowanie-hasla',
        '/order-confirmation': '/potwierzenie-zamowienia',
        '/stores': '/sklepy',
        '/contact': '/kontakty',
        '/product-lines': '/linie-produktowe',
        '/catalog': '/katalog',
        '/login': '/logowanie',
        '/customer': '/klient',
        '/customer/edit-profile': '/klient/edycja-danych',
        '/customer/orders': '/klient/zamowienia',
        '/customer/order-detail': '/klient/szczegoly-zamowienia',
        '/customer/exchange-office': '/klient/kantor',
        '/customer/my-points': '/klient/moje-punkty',
        '/customer/watched-items': '/klient/sledzone-produkty',
        '/registration': '/rejestracja',
        '/terms-and-conditions': '/regulamin-sklepu',
        '/gdpr': '/informacje-o-przetwarzaniu-danych-osobowych',
        '/recover-password': '/nowe-haslo',
        '/personal-data-overview': '/przeglad-danych-osobowych',
        '/personal-data-overview/:hash': '/przeglada-danych-osobowych/:hash',
        '/personal-data-export': '/eksport-danych-osobowych',
        '/order-payment-confirmation': '/potwierdzenie-platnosci-za-zamowienie',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/detail-objednavky/:urlHash',
        '/cookie-consent': '/zgodna-na-pliki-cookies',
        '/abandoned-cart/:cartUuid': '/kosik/:cartUuid',
        '/tescoma-club': '/tescoma-klub',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/bramka-platnosci',
        '/maintenance': '/konserwacja',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/popularne-produkty',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[5]?.url || process.env.DOMAIN_HOSTNAME_6]: {
        '/search': '/wyszukiwanie',
        '/cart': '/koszyk',
        '/order/transport-and-payment': '/zamowienie/dostawa-i-platnosc',
        '/order/contact-information': '/zamowienie/dane-kontaktowe',
        '/b2b-reset-password': '/resetowanie-hasla',
        '/order-confirmation': '/potwierzenie-zamowienia',
        '/stores': '/sklepy',
        '/contact': '/kontakty',
        '/product-lines': '/linie-produktowe',
        '/catalog': '/katalog',
        '/b2b-login': '/logowanie',
        '/customer': '/klient',
        '/customer/edit-profile': '/klient/edycja-danych',
        '/customer/orders': '/klient/zamowienia',
        '/customer/order-detail': '/klient/szczegoly-zamowienia',
        '/customer/exchange-office': '/klient/kantor',
        '/customer/my-points': '/klient/moje-punkty',
        '/customer/watched-items': '/klient/sledzone-produkty',
        '/registration': '/rejestracja',
        '/terms-and-conditions': '/regulamin-sklepu',
        '/gdpr': '/informacje-o-przetwarzaniu-danych-osobowych',
        '/b2b-recover-password': '/nowe-haslo',
        '/personal-data-overview': '/przeglad-danych-osobowych',
        '/personal-data-overview/:hash': '/przeglada-danych-osobowych/:hash',
        '/personal-data-export': '/eksport-danych-osobowych',
        '/order-payment-confirmation': '/potwierdzenie-platnosci-za-zamowienie',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/detail-objednavky/:urlHash',
        '/cookie-consent': '/zgodna-na-pliki-cookies',
        '/abandoned-cart/:cartUuid': '/kosik/:cartUuid',
        '/tescoma-club': '/tescoma-klub',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/bramka-platnosci',
        '/maintenance': '/konserwacja',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/popularne-produkty',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[6]?.url || process.env.DOMAIN_HOSTNAME_7]: {
        '/search': '/kereses',
        '/cart': '/kosar',
        '/order/transport-and-payment': '/rendeles/szallitas-es-fizetes',
        '/order/contact-information': '/rendeles/elerhetosegek',
        '/reset-password': '/elfelejtett-jelszo',
        '/order-confirmation': '/rendeles-megerositese',
        '/stores': '/boltok',
        '/contact': '/kapcsolat-1',
        '/product-lines': '/termekcsaladok',
        '/catalog': '/katalogus',
        '/login': '/bejelentkezes',
        '/customer': '/ugyfel',
        '/customer/edit-profile': '/ugyfel/adatok-szerkesztese',
        '/customer/orders': '/ugyfel/megrendelesek',
        '/customer/order-detail': '/ugyfel/megrendeles-reszletei',
        '/customer/exchange-office': '/ugyfel/penzvalto',
        '/customer/my-points': '/ugyfel/pontjaim',
        '/customer/watched-items': '/ugyfel/kovetett-tetelek',
        '/registration': '/registracio',
        '/terms-and-conditions': '/aszf',
        '/gdpr': '/adatkezelesi-tajekoztato',
        '/recover-password': '/uj-jelszo',
        '/personal-data-overview': '/szemelyes-adatok',
        '/personal-data-overview/:hash': '/szemelyes-adatok/:hash',
        '/personal-data-export': '/szemelyes-adatok-export',
        '/order-payment-confirmation': '/megrendeles-fizetes-megerositese',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/megrendeles-reszletei/:urlHash',
        '/cookie-consent': '/sutik-hozzajarulas',
        '/abandoned-cart/:cartUuid': '/kosar/:cartUuid',
        '/tescoma-club': '/tescoma-klub',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/fizetesi-atjaro',
        '/maintenance': '/karbantartás',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/nepszeru-termekek',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[7]?.url || process.env.DOMAIN_HOSTNAME_8]: {
        '/search': '/kereses',
        '/cart': '/kosar',
        '/order/transport-and-payment': '/rendeles/szállitas-es-fizetes',
        '/order/contact-information': '/rendeles/elerhetosegek',
        '/b2b-reset-password': '/elfelejtett-jelszo',
        '/order-confirmation': '/rendeles-megerositese',
        '/stores': '/boltok',
        '/contact': '/kapcsolat',
        '/product-lines': '/termekcsaladok',
        '/catalog': '/katalogus',
        '/b2b-login': '/bejelentkezes',
        '/customer': '/ugyfel',
        '/customer/edit-profile': '/ugyfel/adatok-szerkesztese',
        '/customer/orders': '/ugyfel/megrendelesek',
        '/customer/order-detail': '/ugyfel/megrendeles-reszletei',
        '/customer/exchange-office': '/ugyfel/penzvalto',
        '/customer/my-points': '/ugyfel/pontjaim',
        '/customer/watched-items': '/ugyfel/kovetett-tetelek',
        '/registration': '/registracio',
        '/terms-and-conditions': '/aszf',
        '/gdpr': '/adatkezelesi-tajekoztato',
        '/b2b-recover-password': '/uj-jelszo',
        '/personal-data-overview': '/szemelyes-adatok',
        '/personal-data-overview/:hash': '/szemelyes-adatok/:hash',
        '/personal-data-export': '/szemelyes-adatok-export',
        '/order-payment-confirmation': '/megrendeles-fizetes-megerositese',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/megrendeles-reszletei/:urlHash',
        '/cookie-consent': '/sutik-hozzajarulas',
        '/abandoned-cart/:cartUuid': '/kosar/:cartUuid',
        '/tescoma-club': '/tescoma-klub',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/fizetesi-atjaro',
        '/maintenance': '/karbantartás',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/nepszeru-termekek',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[8]?.url || process.env.DOMAIN_HOSTNAME_9]: {
        '/search': '/suche',
        '/cart': '/warenkorb',
        '/order/transport-and-payment': '/bestellung/versand-und-zahlung',
        '/order/contact-information': '/bestellung/kundendaten',
        '/reset-password': '/passwort-zurucksetzen',
        '/order-confirmation': '/bestellbestaetigung',
        '/stores': '/stores',
        '/contact': '/kontaktformular',
        '/product-lines': '/produkt-serien',
        '/catalog': '/katalog',
        '/login': '/login',
        '/customer': '/kundenkonto',
        '/customer/edit-profile': '/kundenkonto/daten-bearbeiten',
        '/customer/orders': '/kundenkonto/bestellungen',
        '/customer/order-detail': '/kundenkonto/bestelldetails',
        '/customer/exchange-office': '/kundenkonto/exchange-office',
        '/customer/my-points': '/kundenkonto/meine-punkte',
        '/customer/watched-items': '/kundenkonto/merkliste',
        '/registration': '/registrierung',
        '/terms-and-conditions': '/agb',
        '/gdpr': '/gdpr',
        '/recover-password': '/passwort-wiederherstellen',
        '/personal-data-overview': '/kundendaten-uebersicht',
        '/personal-data-overview/:hash': '/kundendaten-uebersicht/:hash',
        '/personal-data-export': '/kundendaten-export',
        '/order-payment-confirmation': '/bestellung-zahlungsbestaetigung',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/bestelldetails/:urlHash',
        '/cookie-consent': '/cookie-zustimmung',
        '/abandoned-cart/:cartUuid': '/verlassener-Warenkorb/:cartUuid',
        '/tescoma-club': '/tescoma-club',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/zahlungs-gateways',
        '/maintenance': '/wartung',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/beliebte-produkte',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[9]?.url || process.env.DOMAIN_HOSTNAME_10]: {
        '/search': '/suche',
        '/cart': '/warenkorb',
        '/order/transport-and-payment': '/bestellung/versand-und-zahlung',
        '/order/contact-information': '/bestellung/kundendaten',
        '/b2b-reset-password': '/passwort-zurucksetzen',
        '/order-confirmation': '/bestellbestaetigung',
        '/stores': '/stores',
        '/contact': '/kontaktformular',
        '/product-lines': '/produkt-serien',
        '/catalog': '/katalog',
        '/b2b-login': '/einloggen',
        '/customer': '/kundenkonto',
        '/customer/edit-profile': '/kundenkonto/daten-bearbeiten',
        '/customer/orders': '/kundenkonto/bestellungen',
        '/customer/order-detail': '/kundenkonto/bestelldetails',
        '/customer/exchange-office': '/kundenkonto/exchange-office',
        '/customer/my-points': '/kundenkonto/meine-punkte',
        '/customer/watched-items': '/kundenkonto/merkliste',
        '/registration': '/registrierung',
        '/terms-and-conditions': '/agb',
        '/gdpr': '/gdpr',
        '/b2b-recover-password': '/passwort-wiederherstellen',
        '/personal-data-overview': '/kundendaten-uebersicht',
        '/personal-data-overview/:hash': '/kundendaten-uebersicht/:hash',
        '/personal-data-export': '/kundendaten-export',
        '/order-payment-confirmation': '/bestellung-zahlungsbestaetigung',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/bestelldetails/:urlHash',
        '/cookie-consent': '/cookie-zustimmung',
        '/abandoned-cart/:cartUuid': '/verlassener-Warenkorb/:cartUuid',
        '/tescoma-club': '/tescoma-club',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/zahlungs-gateways',
        '/maintenance': '/wartung',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/beliebte-produkte',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[10]?.url || process.env.DOMAIN_HOSTNAME_11]: {
        '/search': '/buscar',
        '/cart': '/carrito',
        '/order/transport-and-payment': '/pedido/transporte-y-pago',
        '/order/contact-information': '/pedido/informacion-de-contacto',
        '/reset-password': '/restablecer-contrasena',
        '/order-confirmation': '/pedido-confirmado',
        '/stores': '/tiendas',
        '/contact': '/contactanos',
        '/product-lines': '/lineas-de-producto',
        '/catalog': '/catalogo',
        '/login': '/iniciar-sesion/registro',
        '/customer': '/cliente',
        '/customer/edit-profile': '/cliente/editar-datos',
        '/customer/orders': '/cliente/pedidos',
        '/customer/order-detail': '/cliente/detalles-pedido',
        '/customer/exchange-office': '/cliente/devolucion',
        '/customer/my-points': '/cliente/mis-puntos',
        '/customer/watched-items': '/cliente/productos-vistos',
        '/registration': '/registro',
        '/terms-and-conditions': '/terminos-y-condiciones',
        '/gdpr': '/rgpd',
        '/recover-password': '/recuperar-contrasena',
        '/personal-data-overview': '/resumen-datos-personales',
        '/personal-data-overview/:hash': '/resumen-datos-personales/:hash',
        '/personal-data-export': '/exportacion-datos-personales',
        '/order-payment-confirmation': '/pedido-confirmacion-pago',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/detalles-pedido/:urlHash',
        '/cookie-consent': '/consentimiento-de-cookies',
        '/abandoned-cart/:cartUuid': '/carrito-abandonado/:cartUuid',
        '/tescoma-club': '/tescoma-club',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/pasarela-de-pago',
        '/maintenance': '/manutenzione',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/productos-populares',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[11]?.url || process.env.DOMAIN_HOSTNAME_12]: {
        '/search': '/pesquisar',
        '/cart': '/carrinho',
        '/order/transport-and-payment': '/encomenda/transporte-e-pagamento',
        '/order/contact-information': '/encomenda/informacao-contacto',
        '/reset-password': '/redefinir-password',
        '/order-confirmation': '/confirmacao-encomenda',
        '/stores': '/lojas',
        '/contact': '/contacto',
        '/product-lines': '/linhas-produtos',
        '/catalog': '/catalogo',
        '/login': '/login',
        '/customer': '/cliente',
        '/customer/edit-profile': '/cliente/editar-datos',
        '/customer/orders': '/cliente/encomendas',
        '/customer/order-detail': '/cliente/detalhes-encomenda',
        '/customer/exchange-office': '/cliente/cambio',
        '/customer/my-points': '/cliente/meus-pontos',
        '/customer/watched-items': '/cliente/produtos-visualizados',
        '/registration': '/registo',
        '/terms-and-conditions': '/termos-e-condicoes',
        '/gdpr': '/rgpd',
        '/recover-password': '/recuperar-password',
        '/personal-data-overview': '/vista-dados-pessoais',
        '/personal-data-overview/:hash': '/vista-dados-pessoais/:hash',
        '/personal-data-export': '/exportacao-dados-pessoais',
        '/order-payment-confirmation': '/confirmacao-pagamento-encomenda',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/detalhes-encomenda/:urlHash',
        '/cookie-consent': '/consentimento-cookies',
        '/abandoned-cart/:cartUuid': '/carrinho-abandonado/:cartUuid',
        '/tescoma-club': '/club-tescoma',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/gateway-de-pagamento',
        '/maintenance': '/manutenzione',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/produtos-populares',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[12]?.url || process.env.DOMAIN_HOSTNAME_13]: {
        '/search': '/search',
        '/stores': '/stores',
        '/contact': '/contacts',
        '/product-lines': '/product-lines',
        '/catalog': '/catalogue',
        '/terms-and-conditions': '/terms-and-conditions',
        '/gdpr': '/gdpr',
        '/personal-data-overview': '/personal-data-overview',
        '/personal-data-overview/:hash': '/personal-data-overview/:hash',
        '/personal-data-export': '/personal-data-export',
        '/cookie-consent': '/cookie-consent',
        '/tescoma-club': '/tescoma-club',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/wishlist',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[13]?.url || process.env.DOMAIN_HOSTNAME_14]: {
        '/search': '/ricerca',
        '/stores': '/negozi',
        '/contact': '/contattateci',
        '/product-lines': '/linee-di-prodotti',
        '/catalog': '/catalogo',
        '/terms-and-conditions': '/termini-e-condizioni',
        '/gdpr': '/regolamento-gdpr',
        '/personal-data-overview': '/panoramica-dati-personali',
        '/personal-data-overview/:hash': '/panoramica-dati-personali/:hash',
        '/personal-data-export': '/esportazione-dati-personali',
        '/cookie-consent': '/consenso-cookie',
        '/tescoma-club': '/tescoma-club',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/prodotti-richiesti',
    },
    [nextConfig?.publicRuntimeConfig?.domains?.[14]?.url || process.env.DOMAIN_HOSTNAME_15]: {
        '/search': '/suche',
        '/cart': '/warenkorb',
        '/order/transport-and-payment': '/bestellung/versand-und-zahlung',
        '/order/contact-information': '/bestellung/kundendaten',
        '/reset-password': '/passwort-zurucksetzen',
        '/order-confirmation': '/bestellbestaetigung',
        '/stores': '/stores',
        '/contact': '/kontaktformular',
        '/product-lines': '/produkt-serien',
        '/catalog': '/katalog',
        '/login': '/login',
        '/customer': '/kundenkonto',
        '/customer/edit-profile': '/kundenkonto/daten-bearbeiten',
        '/customer/orders': '/kundenkonto/bestellungen',
        '/customer/order-detail': '/kundenkonto/bestelldetails',
        '/customer/exchange-office': '/kundenkonto/exchange-office',
        '/customer/my-points': '/kundenkonto/meine-punkte',
        '/customer/watched-items': '/kundenkonto/merkliste',
        '/registration': '/registrierung',
        '/terms-and-conditions': '/agb',
        '/gdpr': '/gdpr',
        '/recover-password': '/passwort-wiederherstellen',
        '/personal-data-overview': '/kundendaten-uebersicht',
        '/personal-data-overview/:hash': '/kundendaten-uebersicht/:hash',
        '/personal-data-export': '/kundendaten-export',
        '/order-payment-confirmation': '/bestellung-zahlungsbestaetigung',
        '/order/payment-status-notify': '/order/payment-status-notify',
        '/order-detail/:urlHash': '/bestelldetails/:urlHash',
        '/cookie-consent': '/cookie-zustimmung',
        '/abandoned-cart/:cartUuid': '/verlassener-Warenkorb/:cartUuid',
        '/tescoma-club': '/tescoma-club',
        '/products/:catNum': '/product/:catNum',
        '/products/:productCatNum': '/video/:productCatNum',
        '/paymentgate-start': '/zahlungs-gateways',
        '/maintenance': '/wartung',
        '/configurator': '/flexi-config',
        '/social-login': '/social-login',
        '/wishlist': '/beliebte-produkte',
    },
} as const;

export type StaticRewritePathKeyType = keyof typeof STATIC_REWRITE_PATHS[string];
