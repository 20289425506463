import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { LoadingOverlay } from 'components/Basic/LoadingOverlay/LoadingOverlay';
import { useCurrentCart } from 'connectors/cart/Cart';
import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { MenuBasketIcon } from 'public/svg/IconsSvg';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { usePersistStore } from 'store/usePersistStore';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

type CartProps = {
    isDesktop: boolean;
};

const Cart: FC<CartProps> = ({ isDesktop }) => {
    const testIdentifier = 'layout-header-cart-';

    const t = useTypedTranslationFunction();
    const formatPrice = useFormatPrice();
    const { cart, isInitiallyLoaded } = useCurrentCart();
    const domainConfig = useDomainConfig();
    const [cartUrl] = getInternationalizedStaticUrls(['/cart'], domainConfig.url);
    const { loginLoading } = usePersistStore((s) => s);

    if (isDesktop) {
        return (
            <div className="relative flex grow justify-end">
                {!isInitiallyLoaded && loginLoading !== 'not-loading' && <LoadingOverlay className="h-8 w-8" />}
                <ExtendedNextLink
                    href={cartUrl}
                    className="defaultTransition flex items-end rounded-md pt-2.5 pr-2 pb-5 text-black no-underline hover:text-black hover:no-underline"
                    type="static"
                >
                    <>
                        <span className="relative flex text-h6">
                            <MenuBasketIcon className="h-6 w-6 stroke-white vl:stroke-black" />
                            {cart?.items || isInitiallyLoaded ? (
                                <span
                                    className="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-red text-[10px] font-bold text-white"
                                    data-testid={testIdentifier + 'itemcount'}
                                >
                                    {cart?.items.length ?? 0}
                                </span>
                            ) : (
                                <Skeleton className="absolute -top-2 -right-2 flex h-4 w-4 rounded-full" />
                            )}
                        </span>
                        <div className="ml-4 font-bold leading-4" data-testid={testIdentifier + 'totalprice'}>
                            <div className="text-tiny font-normal">{t('Your cart')}</div>
                            {cart?.totalItemsPrice || isInitiallyLoaded ? (
                                formatPrice(
                                    (cart?.totalItemsPrice.priceWithVat ?? 0) - (cart?.loyaltyClubDiscount ?? 0),
                                    {
                                        explicitZero: true,
                                    },
                                )
                            ) : (
                                <Skeleton className="h-4 w-14" />
                            )}
                        </div>
                    </>
                </ExtendedNextLink>
            </div>
        );
    }

    return (
        <div className="relative border-x vl:border-none">
            {!isInitiallyLoaded && loginLoading !== 'not-loading' && <LoadingOverlay className="h-8 w-8" />}
            <div className="flex h-11 w-11 cursor-pointer items-center justify-center text-description outline-0">
                <ExtendedNextLink
                    href={cartUrl}
                    className="relative flex h-full w-full items-center justify-center text-white no-underline transition-colors hover:text-white hover:no-underline vl:text-primary"
                    type="static"
                >
                    <>
                        <MenuBasketIcon className="h-6 w-6 stroke-white vl:stroke-black" />
                        {cart?.items || isInitiallyLoaded ? (
                            <span className="absolute right-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red text-[10px] font-bold text-white">
                                {cart?.items.length ?? 0}
                            </span>
                        ) : (
                            <Skeleton
                                className="absolute right-1 top-1 flex h-4 w-4 rounded-full"
                                highlightColor="#cdcdcd"
                            />
                        )}
                    </>
                </ExtendedNextLink>
            </div>
        </div>
    );
};

/* @component */
export default memo(Cart);
