import { FooterContent } from './FooterContent/FooterContent';
import FooterCopyright from './FooterCopyright/FooterCopyright';
import Button from 'components/Forms/Button/Button';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { ChevronIcon } from 'public/svg/IconsSvg';
import { memo } from 'react';
import * as smoothscroll from 'smoothscroll-polyfill';

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

type Props = {
    simple?: boolean;
    withoutMoveUpActionButton?: boolean;
};

const FOOTER_TEST_IDENTIFIER = 'layout-footer';

const Footer = memo<Props>(({ simple, withoutMoveUpActionButton }) => {
    const t = useTypedTranslationFunction();

    useEffectOnce(() => smoothscroll.polyfill());

    return (
        <div className="relative flex flex-col pt-14 pb-40 vl:pt-12 vl:pb-20" data-testid={FOOTER_TEST_IDENTIFIER}>
            {!withoutMoveUpActionButton && (
                <Button
                    className="absolute -top-5 left-1/2 -translate-x-1/2 self-center whitespace-nowrap px-7 vl:hidden [&>svg:first-child]:mr-2.5"
                    type="button"
                    size="small"
                    onClick={scrollToTop}
                >
                    <ChevronIcon className="h-2.5 w-2.5 first:mr-2" />
                    <span>{t('Move up')}</span>
                </Button>
            )}
            {!simple && <FooterContent />}
            <FooterCopyright />
        </div>
    );
});

Footer.displayName = 'Footer';

export default Footer;
