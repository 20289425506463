import {
    ConfiguratorDrawerSizesType,
    ConfiguratorProductType,
    ConfiguratorProductTypeSummed,
} from 'types/configurator';

export const getIsProductHaveSpaceBeforeDrawerEnding = (
    productToCheck: ConfiguratorProductType,
    drawerSizes: ConfiguratorDrawerSizesType,
) => {
    const byLength = drawerSizes.length - productToCheck.coordinates.y >= productToCheck.height;
    const byWidth = drawerSizes.width - productToCheck.coordinates.x >= productToCheck.width;

    return {
        byWidth,
        byLength,
    };
};

type RangeType = {
    start: number;
    end: number;
};

export const getIsRangesOverlap = (firstRange: RangeType, secondRange: RangeType) => {
    return !(firstRange.end <= secondRange.start || firstRange.start >= secondRange.end);
};

export const getIsProductHaveCollision = (
    productToCheck: ConfiguratorProductType,
    productsInDrawer: ConfiguratorProductType[],
    shouldSkipCurrentProduct = false,
) => {
    let doesHaveCollision = false;

    productsInDrawer.forEach((productInDrawer) => {
        const doesHaveCollisionByX = getIsRangesOverlap(
            {
                start: productToCheck.coordinates.x,
                end: productToCheck.coordinates.x + productToCheck.width,
            },
            {
                start: productInDrawer.coordinates.x,
                end: productInDrawer.coordinates.x + productInDrawer.width,
            },
        );
        const doesHaveCollisionByY = getIsRangesOverlap(
            {
                start: productToCheck.coordinates.y,
                end: productToCheck.coordinates.y + productToCheck.height,
            },
            {
                start: productInDrawer.coordinates.y,
                end: productInDrawer.coordinates.y + productInDrawer.height,
            },
        );

        if (
            !shouldSkipCurrentProduct &&
            productInDrawer.id !== productToCheck.id &&
            doesHaveCollisionByX &&
            doesHaveCollisionByY
        ) {
            doesHaveCollision = true;
        }

        return null;
    });

    return doesHaveCollision;
};

export const calculateDrawerPaddings = (drawerSizesWithoutRounding: ConfiguratorDrawerSizesType) => {
    return {
        lengthPadding: drawerSizesWithoutRounding.length - Math.floor(drawerSizesWithoutRounding.length),
        widthPadding: drawerSizesWithoutRounding.width - Math.floor(drawerSizesWithoutRounding.width),
    };
};

export const groupProductsInDrawer = (products: ConfiguratorProductType[]): ConfiguratorProductTypeSummed[] => {
    const productMap: { [key: string]: ConfiguratorProductTypeSummed } = products.reduce(
        (acc: { [uuid: string]: ConfiguratorProductTypeSummed }, product) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const existingProduct = acc[product.uuid] || { ...product, quantity: 0 };

            acc[product.uuid] = {
                ...existingProduct,
                quantity: existingProduct.quantity + 1,
            };

            return acc;
        },
        {},
    );

    return Object.values(productMap);
};
