import { SliderProductItem } from './SliderProductItem';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { useGtmProductListView } from 'hooks/gtm/useGtmProductListView';
import { useGetVisibleSliderIndexes } from 'hooks/slider/useGetVisibleSliderIndexes';
import { useLatest } from 'hooks/ui/useLatest';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { useWishlist } from 'hooks/wishlist/useWishlist';
import { ArrowIcon } from 'public/svg/IconsSvg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { GtmListNameType } from 'types/gtm';
import { SliderProductItemType } from 'types/product';
import twMerge from 'utils/twMerge';

const INITIAL_SLIDER_SETTINGS: EmblaOptionsType = {
    containScroll: 'trimSnaps',
    dragFree: false,
    watchDrag: true,
    duration: 20,
    startIndex: 0,
};

function getSliderSettings(width: number, showNavigation: boolean): EmblaOptionsType {
    if (width <= desktopFirstSizes.mobile) {
        return {
            ...INITIAL_SLIDER_SETTINGS,
            align: 'center',
            watchDrag: showNavigation,
        };
    }

    return {
        ...INITIAL_SLIDER_SETTINGS,
        align: 'start',
        watchDrag: showNavigation,
        loop: false,
        skipSnaps: false,
    };
}

type ProductsSliderProps = {
    gtmListName: GtmListNameType;
    noMarginBottom?: boolean;
    shouldRedirectOnClick?: boolean;
    products: SliderProductItemType[];
    onProductItemClick?: (product: SliderProductItemType) => void;
    isConfigurator?: boolean;
};

const productSliderArrowTwClass =
    'absolute top-1/2 z-[481] -mt-1 flex h-10 w-10 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border-none bg-black text-white outline-none active:scale-[0.97] transition-colors duration-200 ease-defaultTransition hover:bg-green';

const ProductsSlider: FC<ProductsSliderProps> = ({
    gtmListName,
    noMarginBottom,
    products,
    onProductItemClick,
    shouldRedirectOnClick = true,
    isConfigurator,
}) => {
    useGtmProductListView(products, gtmListName);

    const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [emblaApiReady, setEmblaApiReady] = useState(false);

    const [emblaRef, emblaApi] = useEmblaCarousel(INITIAL_SLIDER_SETTINGS);
    const latestEmblaApi = useLatest(emblaApi);

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

    const containerRef = useRef<HTMLDivElement>(null);

    /* watch if embla api is ready and bind its select event */
    useEffect(() => {
        if (!emblaApi) {
            return void null;
        }
        setEmblaApiReady(true);

        const onSelectHandler = () => {
            setPrevButtonEnabled(emblaApi.canScrollPrev());
            setNextButtonEnabled(emblaApi.canScrollNext());
            setSelectedIndex(emblaApi.selectedScrollSnap());
        };
        onSelectHandler();
        emblaApi.on('select', onSelectHandler);

        return () => {
            emblaApi.off('select', onSelectHandler);
        };
    }, [emblaApi]);

    /* if embla api is ready listen for window width or products changes and re-initialize */
    useEffect(() => {
        const _emblaApi = latestEmblaApi.current;
        if (!emblaApiReady || !_emblaApi) {
            return;
        }

        _emblaApi.reInit(getSliderSettings(window.innerWidth, _emblaApi.canScrollPrev() || _emblaApi.canScrollNext()));
        setPrevButtonEnabled(_emblaApi.canScrollPrev());
        setNextButtonEnabled(_emblaApi.canScrollNext());
        setSelectedIndex(_emblaApi.selectedScrollSnap());
    }, [emblaApiReady, latestEmblaApi, products]);

    const isMobile = !useMediaMin('lg');
    const isTablet = !useMediaMin('vl');
    const isTabletLarge = !useMediaMin('xl');

    const getVisibleSliderIndexes = () => {
        let length;

        switch (true) {
            case isMobile:
                length = 2;
                break;
            case isTablet:
                length = 3;
                break;
            case isTabletLarge:
                length = 4;
                break;
            default:
                length = 5;
                break;
        }

        return Array.from({ length }, (_, index) => index + selectedIndex);
    };

    const visibleSliderIndexes = useGetVisibleSliderIndexes(emblaApi, getVisibleSliderIndexes());
    const { toggleProductInWishlist, isProductInWishlist } = useWishlist();

    return (
        <div className="relative">
            {(prevButtonEnabled || nextButtonEnabled) && (
                <>
                    <button
                        className={twMerge(
                            productSliderArrowTwClass,
                            !prevButtonEnabled && 'pointer-events-none hidden bg-grayLighter md:flex',
                            'left-0 rotate-90 md:-top-10 md:left-[unset] md:right-12 md:translate-y-0',
                        )}
                        onClick={scrollPrev}
                    >
                        <ArrowIcon className="h-4 w-4 text-currentColor" />
                    </button>
                    <button
                        className={twMerge(
                            productSliderArrowTwClass,
                            !nextButtonEnabled && 'pointer-events-none hidden bg-grayLighter md:flex',
                            'right-0 -rotate-90 md:-top-10 md:right-0 md:translate-y-0',
                        )}
                        onClick={scrollNext}
                    >
                        <ArrowIcon className="h-4 w-4 text-currentColor" />
                    </button>
                </>
            )}
            <div
                className={twJoin(
                    'relative mb-11 h-[475px] min-w-full overflow-hidden py-4 lg:mt-6 lg:mb-14 lg:h-[540px] vl:h-[550px]',
                    noMarginBottom && 'mb-0',
                    prevButtonEnabled && 'md:!before:hidden before:!block',
                    nextButtonEnabled && 'md:!after:hidden after:!block',
                    'before:pointer-events-none before:absolute before:left-0 before:top-0 before:bottom-0 before:z-productSlider before:hidden before:w-14 before:content-[""] before:[background:linear-gradient(to_right,_#ffffff_0%,_#ffffff00_100%)]',
                    'after:pointer-events-none after:absolute after:top-0 after:bottom-0 after:right-0 after:z-productSlider after:hidden after:w-14 after:content-[""] after:[background:linear-gradient(to_left,_#ffffff_0%,_#ffffff00_100%)]',
                )}
                ref={emblaRef}
            >
                <div className="relative flex h-full w-full" ref={containerRef}>
                    {products.map((productItemData, index) => (
                        <SliderProductItem
                            onProductItemClick={onProductItemClick}
                            gtmListName={gtmListName}
                            key={productItemData.uuid}
                            listIndex={index}
                            product={productItemData}
                            shouldRedirectOnClick={shouldRedirectOnClick}
                            selected={index === selectedIndex}
                            isContentVisible={visibleSliderIndexes.includes(index)}
                            isConfigurator={isConfigurator}
                            isProductInWishlist={isProductInWishlist(productItemData.uuid)}
                            toggleProductInWishlist={() => toggleProductInWishlist(productItemData.uuid)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductsSlider;
