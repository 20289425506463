import { useChangePaymentMutation } from './useChangePaymentInCart';
import { handleCartModifications, useCurrentCart } from 'connectors/cart/Cart';
import { CartQueryApi, CartQueryDocumentApi, CartQueryVariablesApi } from 'graphql/generated';
import { getUrlWithoutGetParameters } from 'helpers/parsing/urlParsing';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { usePersistStore } from 'store/usePersistStore';
import { useSessionStore } from 'store/useSessionStore';
import { useClient } from 'urql';

export const useReloadCart = (): void => {
    const { modifications } = useCurrentCart(false);
    const t = useTypedTranslationFunction();
    const router = useRouter();
    const slug = getUrlWithoutGetParameters(router.asPath);
    const { isUserLoggedIn } = useCurrentUserData();
    const [changePaymentInCart] = useChangePaymentMutation();
    const updateLastVisitedSlug = useSessionStore((store) => store.updateLastVisitedSlug);
    const lastVisitedSlug = useSessionStore((store) => store.lastVisitedSlug);
    const client = useClient();
    const { cartUuid, defaultStoreUuid, selectedStoreUuid } = usePersistStore((s) => s);

    useEffect(() => {
        const hasSlugChanged = lastVisitedSlug !== slug;
        updateLastVisitedSlug(slug);

        if ((cartUuid || isUserLoggedIn) && hasSlugChanged) {
            client
                .query<CartQueryApi, CartQueryVariablesApi>(
                    CartQueryDocumentApi,
                    { cartUuid, defaultStoreUuid, selectedStoreUuid },
                    { requestPolicy: 'network-only' },
                )
                .toPromise();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    useEffect(() => {
        if (modifications !== null) {
            handleCartModifications(modifications, t, changePaymentInCart);
        }
    }, [changePaymentInCart, modifications, t]);
};
